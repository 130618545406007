.patients-page {
    .header-wrapper{
        height: 90px;
        border-bottom: none;
    }
}

.create-patients-btn {
    position: absolute;
    right: 34px;
    top: 24px;
    z-index: 1;
}

#copy-address-from-request-btn {
    margin-bottom: 15px;
}