.logging-card {
  .card__header {
    padding: 5px 0 0 0;
  }

  .card__body {
    padding: 0;
    margin-top: 14px;
  }

  .card__item {
    margin-top: 0;
    margin-bottom: 1px;
    padding: 12px 16px;
    background-color: #F8F8F8;
  }

  .card__subtitle {
    color: #474849;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: none;
  }

  .card__text {
    padding-top: 6px;
    color: #474849;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}
