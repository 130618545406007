.right-side-bar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 600px;
    background-color: #fff;
    border-left: 1px solid #e0e0e0;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.15);
    z-index: 1002;
    display: flex;
    flex-direction: column;

    .right-side-bar-header {
        padding: 23px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .right-side-bar-title {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 22px;
            max-width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-subtext {
            flex-basis: 100%;
            color: #474849;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
            padding: 23px 0 0 67px;
        }

        div {
            &.actions {
                margin-left: auto;
                display: flex;
                width: auto;
            }
        }

        button {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        .actions button {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    .right-side-bar-body {
        padding: 23px;
        overflow-y: auto;
        flex: 1;

        &::-webkit-scrollbar {
            height: 28px;
            width: 18px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0px 0px 10px 10px transparent;
            border: solid 0px transparent;
        }

        &::-webkit-scrollbar-thumb:vertical {
            box-shadow: inset 0 0 10px 10px #d8d8d8;
            border-top: solid 10px transparent;
            border-left: solid 7px transparent;
            border-bottom: solid 10px transparent;
            border-right: solid 7px transparent;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
        }

        .base-input-wrapper {
            margin-bottom: 14px;
        }

        .custom-select-wrapper {
            margin-bottom: 14px;
        }
    }
}

.dialog-with-subcontent {
    .right-side-bar-header{
        border-bottom: none;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}
