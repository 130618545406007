.confirm-popup {
  width: 100%;
  padding: 25.5px;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);

  &__body {
    margin-bottom: 20px;
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  &__actions {
     display: flex;
     align-items: center;
    button {
      display: inline-flex;
      justify-content: center;
      min-width: 100px;
    }
  }
  &__cancel {
    margin-left: auto;
    margin-right: 20px;
  }
  &__confirm {
    margin-right: auto;
  }
}

