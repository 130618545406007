.fake-tabs {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
  cursor: pointer;
  background:#ffffff;

  &__actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;

    .custom-field-outer {
      max-width: 270px;
      min-width: 270px;
    }
  }
  &__item {
    font-family: 'Inter', sans-serif;
    padding: 21px 0;
    margin-right: 40px;
    color: rgba(0,0,0,0.5);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
  &--active {
    color: #474849;
    border-bottom: 1px solid #474849;
  }
}

.mt-28 {
  margin-top: 28px;
}
.ml-34 {
  margin-left: 34px;
}
.pl-34 {
  padding-left: 34px;
}
