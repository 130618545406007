.rates-wrapper {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;

    .total-row-bar {
        padding-top: 5px!important;
    }
    .main-info-wrapper {
        flex: 1 1 auto;
        height: 585px!important;
    }
    .main-info-wrapper:nth-child(2) {
        height: 500px!important;
        .ag-table-main {
            margin-top: 0;
        }
    }
}
