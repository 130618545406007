.create-form {
    h3 {
        color: #474849;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 14px;
        margin-top: 30px;
    }

    h3:first-of-type {
        margin-top: 0;
    }

    .edit-form {
        width: 384px;
        outline: none;

        .row-two-column {
            display: flex;
            justify-content: space-between;

            .field-set {
                &.edit-field {
                    width: 185px;
                }
            }
        }
    }

    .field-list-section {
        .field-list-item {
            .email {
                .base-input-label {
                    left: 30px;
                }
                input {
                    padding-left: 30px;
                }
            }

            .function-wrapper {
                width: 100%;

                .required-field-star {
                    &::before {
                        content: '*';
                    }
                }
            }
        }
    }

    .field-list-section-single,
    .field-list-section-no-draggable {
        .field-list-item {
            .email,
            .other-role {
                .base-input-label {
                    left: 15px;
                }
                input {
                    padding-left: 15px;
                }
            }
        }
    }
}
