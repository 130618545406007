$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-add: "\e900";
$icon-admin: "\e901";
$icon-authorization: "\e902";
$icon-back: "\e903";
$icon-calendar: "\e904";
$icon-cellphone: "\e905";
$icon-check: "\e906";
$icon-close: "\e907";
$icon-data: "\e908";
$icon-delete: "\e909";
$icon-down: "\e90a";
$icon-drag: "\e90b";
$icon-dropdown: "\e90c";
$icon-edit: "\e90d";
$icon-export: "\e90e";
$icon-facility: "\e90f";
$icon-favourite: "\e910";
$icon-invoice: "\e911";
$icon-job: "\e912";
$icon-map: "\e913";
$icon-open-in: "\e914";
$icon-options: "\e915";
$icon-people: "\e916";
$icon-log-out: "\e917";
$icon-info: "\e918";
$icon-email: "\e919";
$icon-person: "\e91a";
$icon-phone: "\e91b";
$icon-print: "\e91c";
$icon-signature: "\e91d";
$icon-search: "\e91e";
$icon-service: "\e91f";
$icon-show: "\e920";
$icon-sort: "\e921";
$icon-sub: "\e922";
$icon-technician: "\e923";
$icon-time: "\e924";
$icon-upload-file: "\e925";
$icon-user-management: "\e926";
$icon-WiFi: "\e927";
$icon-golas: "\e928";
$icon-launch: "\e929";
$icon-switch: "\e92a";
$icon-tasks: "\e92b";
$icon-walk: "\e92c";
$icon-heart: "\e92d";
$icon-image: "\e92e";
$icon-minus: "\e92f";
$icon-pdf: "\e930";
$icon-play: "\e931";
$icon-talk: "\e932";
$icon-video: "\e933";
$icon-file: "\e934";
$icon-home: "\e935";
$icon-settings: "\e936";
$icon-logging: "\e937";
$icon-notes: "\e938";
$icon-list: "\e939";
$icon-document: "\e93a";
$icon-history: "\e93b";
$icon-video-camera: "\e93c";
$icon-eye-on: "\e93d";
$icon-eye-off: "\e93e";
$icon-marker: "\e93f";
$icon-loop: "\e940";
