.right-sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.create-therapist-form {
    width: 350px;
}

.therapist-page {
    .header-wrapper {
        height: 90px;
        border-bottom: none;
    }

    .cell-with-tooltip-wrapper {
        .table-tooltip-wrapper {
            width: fit-content;
            left: -7px;
        }
    }
}

.create-therapist-btn {
    position: absolute;
    right: 34px;
    top: 24px;
    z-index: 1;
}

.edit-therapist-field {
    position: relative;

    &.document-field {
        .custom-select-wrapper {
            &::after {
                content: $icon-dropdown;
            }
        }
    }

    &.date-picker-field {
        position: relative;
    }

    .error-message-for-input {
        position: absolute;
        bottom: -12px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .phone {
        display: flex;

        .custom-field-outer {
            width: 240px;
            &:first-of-type {
                width: 110px;

                .custom-select-wrapper {
                    border-right: none;
                }
            }
        }

        .error-message-for-input {
            bottom: 3px;
        }
    }

    &.internal-note,
    &.inactive-reason {
        &.required-field-star {
            &::before {
                position: absolute;
                content: '*';
                left: -15px;
                top: 36px;
                color: #e94a4a;
                font-size: 20px;
            }
        }
    }
}

.managed-by-autocomplete .custom-select-wrapper {
    border-style: none;
}

.details {
    .tabs {
        &.vertical {
            .tabs-active-content {
                width: 70%;
            }
        }
    }
}
