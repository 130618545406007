.details {
  header {
    padding-bottom: 0% !important;
  }
}

.lead-header-info {

  .lead-general-info {
    padding: 0 34px;

    .bio {
      height: 50px;
      display: flex;
      margin-bottom: 16px;

      .personal-data {
        .fullname {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
        }

        .note {
          margin-top: 6px;
          color: rgba(71, 72, 73, 0.7);
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }

    .contact-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .contact-info-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 60%;

        .chip {
          margin-bottom: 10px;
          min-width: 160px; //fix for short phones, like 1111111111
        }

        .chip:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}