.therapist-interview-list {

    .table-header-top-bar {
      width: 100%;
      margin-left: auto;
      background: none;
      padding: 23px 34px 23px 0;

      .vertical-separator {
        height: 100%;
      }

      .custom-field-outer {
          display: flex;
          align-items: flex-end;

          .base-input-wrapper-with-ico {
              height: 40px;

              .base-input-label {
                  color: #474849;
              }
          }
      }

      button {
          &::before {
              display: none;
          }
      }
    }

    .therapist-interview-list-status {
        color: #ffffff;
        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
        text-align: center;
        padding: 2px 4.5px;
        text-transform: uppercase;
        background-color: rgba(248, 248, 248, 0.4);

        &.new {
            background-color: #F5A623;
        }

        &.completed {
            background-color: #43910A;
        }

        &.scheduled {
            background-color: #007AFF;
        }

        &.cancelled {
            background-color: #FF595F;
        }
    }

  .location-link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .location-link {
      display: flex;
      span.icon {
        font-size: 24px;
      }
    }
  }

  .ag-header {
     display: unset;
    .ag-header-viewport .ag-header-container {
       padding-left: 0 !important;
    }
  }

  .ag-body-horizontal-scroll,
  .ag-status-bar {
    padding-left: 0!important;
  }
}
