.refresh-popup {
  width: 100%;
  padding: 25.5px;
  padding-top: 15px;
  padding-right: 10px;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
  display: flex;

  &__close-button {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
    cursor: pointer;
  }
  &__body {
    padding-top: 5px;
    margin-bottom: 20px;
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  &__actions {
     display: flex;
     align-items: center;
    button {
      display: inline-flex;
      justify-content: center;
      min-width: 100px;
    }
  }
  &__refresh {
    margin-right: auto;
    margin-left: 0;
  }
  .icon-loop {
    font-size: 14px;
  }
}

.refresh-popup-wrapper {
  z-index: 1004;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
