.input-file-wrapper {
  border: 1px solid #E0E0E0;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding-left: 10.5px;
  position: relative;

  .input-file-name {
    position: absolute;
    top: 50%;
    transform: translateY(-20%);
    left: 15px;
    width: 290px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 16px;
  }

  label {
    font-size: 16px;
    font-weight: initial;
    position: relative;
    top: 0;
    left: 0;
    transition: all .3s ease;

    &.floated {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
      top: -12px;
      left: 5px;
      transition: all .3s ease;
    }
  }

  .input-file {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }

  .icon {
    font-size: 24px;
    margin-right: 10px;
    position: absolute;
    right: 0;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &.with-error {

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: rgb(233, 74, 74);
      z-index: 1;
    }
  }
}
