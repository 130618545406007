@mixin actions-button ($color) {
    border: none;
    color: $color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    background: inherit;
    outline: none;
    cursor: pointer;
}

.leads-management-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    position: relative;

    header {

        .header-wrapper {
          border: none;
          height: 100%;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .table-header-top-bar {
        padding: 16px 34px 16px 0;
        border-bottom: 1px solid #e0e0e0;

        .top-filter-status-field {

            .custom-select-wrapper {
                width: fit-content;

                .custom-select-display {
                    padding: 20px 40px 8px 15px;
                }

                &.open {

                    .option-list {
                        width: 100%;
                        min-width: 210px;
                    }
                }
            }
        }

        .table-header-actions {
            flex-direction: row-reverse;

            .options-button-wrapper {
                button {
                    margin: 0;
                }
            }
        }
    }

    .main-info-wrapper {
        .main-table-wrapper {
            padding: 25px 25px 0 0;
            margin-left: 34px;

            .table-counter-badge {
                background-color: #F5F5F5;
                padding: 6px 10px;
                width: fit-content;
                min-width: 100px;

                .leads-counter {

                    .leads-counter_text {
                        font-size: 10px;
                        font-weight: 500;
                        text-transform: uppercase;
                        top: 13px;
                        transition: all .3s ease;
                    }

                    .leads-counter_count{
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 22px;
                    }
                }
            }

            .resume-column {
                width: 52px;
                min-width: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                .icon-wrapper {
                    display: flex;
                    justify-content: center;

                    .icon {
                        font-size: 24px;
                        color: #474849;
                    }
                }

                .table-data-inner-text  {
                    position: relative;
                    transform: none;
                    top: inherit;
                    left: inherit;
                }
            }

            .header-resume,
            .actions-resume {

                .icon-down {
                    display: none;
                }
            }
            .header-resume {
                width: 52px;
                min-width: 52px;
            }

            .lead-first-name-column {
                display: flex;
                align-items: center;

                .icon {
                    &.icon-info {
                        margin-left: 2px;
                        font-size: 18px;

                        &:before {
                            color: #F5A623;
                        }
                    }
                }
            }
        }
    }

    .similar-leads-dialog-wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.actions-leads {
    display: flex !important;
    .actions-button-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .verify-btn {
            @include actions-button (#0192C9);
            background-color: #0192C9;
            -webkit-mask-image: url('../../common/images/checkmark.svg');
            mask-image: url('../../common/images/checkmark.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 55px;
            color: white;
            height: 100%;
            width: 25px;
        }

        .refuse-btn {
            @include actions-button (#9E9E9E);
            background-color: #9E9E9E;
            -webkit-mask-image: url('../../common/images/x-symbol.svg');
            mask-image: url('../../common/images/x-symbol.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 19px;
            height: 100%;
            width: 25px;

            &.refused {
                margin-left: 105px;
                cursor: default;
            }
        }
    }
}

.tooltip-lead-actions {
    width: 60px;
    font-size: 14px;
    line-height: 14px;
}

.lead-status-column-value {
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    padding: 2px 4.5px;
    text-transform: uppercase;
    background-color: rgba(248, 248, 248, 0.4);

    &.fixed-width {
        display: inline-block;
        min-width: 70px;
    }

    &.verified {
        background-color: rgb(35, 87, 2);
    }

    &.new {
        background-color: rgb(0, 122, 255);
    }

    &.refused {
        background-color: rgb(255, 89, 95);
    }

    &.inprogress {
        background-color: #474849;
    }
}
