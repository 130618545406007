.therapist-assignemnt {

    .therapist-document-name-column {
        min-width: 335px;
    }

    .therapist-document-date-column {
        min-width: 165px;
    }

    .therapist-document-file-column {
        min-width: 105px;
    }

    .table-data {

        &.not-uploaded {

            .table-data-inner-text {
                color: #474849;
            }
        }

        &.uploaded {

            .table-data-inner-text {
                color: #43910A;
            }
        }

        &.expired {

            .table-data-inner-text {
                color: #FF595F;
            }
        }

        &.overridden {

            .table-data-inner-text {
                color: #F5A623;
            }
        }
    }

    .link-button {
        display: flex;
        align-items: center;
        .icon {
            height: 20px;
        }
    }

    .table-page {
        min-height: 100%;
    }
    .ag-row-level-1 {
        background-color: #e6e6e6 !important;
    }

    div[id='headerColumn therapistDocumentCheckbox'] {
        overflow: visible !important;
    }
}

.document-export-wrapper {
    display: flex;
    align-items: center;
    .export-button {
        height: 100%;
    }
}

.document-export-list {
    width: 220px!important;
}
