.update-therapist-profile-photo {
    margin-top: 30px;

    .avatar-wrapper {
        width: 50px;
        height: 50px;
    }
    .avatar-wrapper img {
        width: 50px;
    }

    .description {
        color: rgba(71, 72, 73, 0.7);
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-transform: uppercase;
    }

    .avatar-header {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
    }

    .inline {
        float: left;
    }

    .image-preview {
        margin-right: 10px;
    }

    .image-upload-button {
        margin-top: 10px;
    }

    .update-section {
        margin-top: 10px;
    }
}
