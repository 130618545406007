.rate-form{
    .right-side-bar {
        .right-side-bar-header {
            border-bottom: none;   
        }

        .rate-template-types {
            display: flex;
            padding-left: 23px;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    .view-field  {
        min-height: 50px;
        min-width: 150px;
        padding: 8px 0 5px 0;
    
        .base-input-label {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        }
    
        .view-value {
        letter-spacing: 0;
        line-height: 22px;
        }
    
        &.display-internal-note {
    
        .view-value {
            word-wrap: break-word;
        }
        }
    }

    #startDateView {
        margin-bottom: 5px;
    }

    .change-rate-line {
        border-top: solid 1px #e0e0e0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .change-rate-header {        
        padding-top: 10px;
        padding-bottom: 10px;
    }
}