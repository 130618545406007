.nursing-home-basic-form {
    width: 365px;
    .title {
        display: flex;
        align-items: center;
        flex-direction: row;

        .icon {
            font-size: 24px;
            margin-right: 10px;
        }
    }

    .card {
        margin-right: 0;

        &.void-card {
            .card__body {
                padding-left: 0;
            }
        }
    }

    .row-two-column {
        .react-datepicker-popper {
            min-width: 220px;
        }
    }
    .option-roles {
        font-size: 12px;
        color: #474849;
        margin-top: 5px;
        line-height: 18px;
        span {
            font-weight: bold;
            margin-top: -5px;
        }
    }
}

.custom-rates-table {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #474849;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-left: 30px;
    width: calc(100% - 30px);
    cursor: pointer;
    .icon {
        font-size: 24px;

        &.dropdown-icon-to-top {
            transform: rotate(180deg);
        }
    }
}

.rate-table {
    .ag-table .ag-row {
        cursor: default !important;
    }
}
