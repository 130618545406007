.request-grid-wrapper {
  display: grid;
  grid-template-columns:  repeat(5, 1fr);
  width: 100%;
  max-width: 2000px;
  row-gap: 10px;
  gap: 20px;
  > div {
    margin: 0;
  }

  .box-1 {
    grid-row-start: 1;
    grid-row-end: 100;
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .box-2 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .box-3 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
  }

  .box-4 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 4;
    grid-column-end: 6;
    min-width: 690px;
  }
}

.request-grid-wrapper.w-1890 {
  grid-template-columns:  repeat(4, 1fr);
  max-width: 1400px;
  .box-4 {
    grid-row-start: 2;
    grid-row-end: 16;
    grid-column-start: 2;
    grid-column-end: 4;
    min-height: 100%;
    min-width: 752px;
  }
}
.request-grid-wrapper.w-1426 {
  grid-template-columns:  repeat(3, 1fr);
  max-width: 1153px;
  .box-4 {
    grid-row-start: 2;
    grid-row-end: 16;
    grid-column-start: 2;
    grid-column-end: 4;
    min-height: 100%;
    min-width: 650px;
    width: 100%;
  }
}
.request-grid-wrapper.w-1260 {
  grid-template-columns:  repeat(2, 1fr);
  .box-1 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .box-2 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .box-3 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .box-4 {
    grid-row-start: 4;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 4;
    min-width: 100%;
    margin-top: 0;
    min-height: 230px;
  }
}
.request-grid-wrapper.w-850 {
  grid-template-columns:  repeat(1, 1fr);
  .box-1 {
    grid-row-end: 1;
    grid-column-end: 2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .box-2 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .box-3 {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .box-4 {
    grid-row-start: 4;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 4;
    min-width: 100%;
    margin-top: 0;
    min-height: 230px;
  }
}
.general-tab {
  .tabs-nav {
    max-width: 170px!important;
  }
  .tabs-active-content {
    padding-left: 24px!important;
    padding-right: 12px;
  }
}
