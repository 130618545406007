.field-list-section {
    flex-direction: column;

    &:not(.field-list-section-single) {
        .field-list-item {
            .base-input-wrapper .base-input-label {
                padding-left: 15px;
            }
            .base-input-wrapper input {
                padding: 5px 15px 0 29px;
            }
        }
    }

    .field-list-item {
        display: flex;
        position: relative;

        .icon {

            &.icon-drag {
                font-size: 24px;
                color: #9E9E9E;
                position: absolute;
                top: 14px;
                z-index: 1;
                margin: 0 4px;
            }
        }
    }

    .add-button {
        width: 100%;
        padding: 15px;
        font-size: 16px;
        outline: none;
        position: relative;

        .plus{
            position: absolute;
            right: 12px;
            color: #0192C9;
            font-size: 24px;
        }
    }

    .delete-button {
        padding: 15px 5px;
        height: 50px;
        position: relative;
        right: 0;

        span {
            color: #474849;
        }
    }
}

.field-set {
    width: 100%;
    position: relative;
}

.patient-practice {
    margin-top: 10px;
}
.hide-practice {
    .patient-practice {
       display: none;
    }
}
