.departments {
  width: 100%;

  .general-info-actions-wrapp {
    margin: 0;
    .label {
      opacity: 0.8;
      color: #474849;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
    }
  }

  &__items {
    padding: 16px;
    padding-bottom: 0;

    &:after {
      content: '';
      border-bottom: 1px solid #E0E0E0;
      width: 100%;
      display: block;
      padding-top: 16px;
    }
  }

  &__items:last-child {
    padding-bottom: 16px;
    &:after {
      content: '';
      display: none
    }
  }

  &__last-update {
    height: 16px;
    color: rgba(71,72,73,0.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: right;
  }

  &__selects {
    display: flex;
  }

  &__employment-status {

    & .custom-select-display {
      min-width: 221px;
      max-width: 221px;
      width: 221px;

      & >div {
        position: relative;
        display: flex;
        min-width: 180px;
        max-width: 180px;
        width: 180px;
      }

      .select-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__add-button {
    margin-top: 16px;
  }
}
