.table-header-top-bar {
    padding: 16px 34px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;

    &__left-section {
        display: inline-flex;

        .custom-field-outer {
            margin-right: 15px;
        }

        .base-input-wrapper-with-ico {
            width: 285px;
        }

        .custom-select-wrapper {
            width: 180px;

            .option-list {
                width: 130%;

                &::-webkit-scrollbar {
                    height: 28px;
                    width: 18px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 10px 10px transparent;
                    border: solid 0 transparent;
                }

                &::-webkit-scrollbar-thumb:vertical {
                    box-shadow: inset 0 0 10px 10px #D8D8D8;
                    border-top: solid 10px transparent;
                    border-left: solid 7px transparent;
                    border-bottom: solid 10px transparent;
                    border-right: solid 7px transparent;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
                }
            }
        }
    }

    .cancel-selected-rows{
        display: flex;
        align-items: center;

        .selected-count {
            margin-left: 10px;
        }
    }
}

.table-header-top-bar,
.table-body-wrapper {
    .icon-down {
        font-size: 21px;
        color: #9e9e9e;
        font-family: "icomoon";
        top: 50%;
        right: 3px;
        transform: rotate(270deg);
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.expanded {
            transform: rotate(0deg);
            color: #0192c9;
        }

        &.to-top {
            transform: translateY(-50%) rotateX(180deg);
            background-color: #e5f4f9;
            color: #0192c9;
        }
        &.to-bottom {
            background-color: #e5f4f9;
            color: #0192c9;
        }

        &:hover {
            background-color: #e5f4f9;
            color: #0192c9;
        }
    }
}

.subgrid-table {
    display: block;
    margin-left: 20px;
}

.selected-filters-counter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: #0192C9;
    color: #fff;
}

.expandable-table{

    .expandable-row{
        display: block;

        .expandable-table-item-container{
            display: flex;
            flex-flow: row wrap;

            .table-item{
                height: 40px;
                &:first-of-type {
                    width: 80px;
                    flex-grow: 0;
                    flex-basis: auto;
                }
                &:last-of-type {
                    width: 50px;
                    flex-grow: 0;
                    flex-basis: auto;
                }
                flex-basis: 100px;
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #474849;
                font-size: 16px;
                letter-spacing: 0;
                padding-left: 14px;
                background-color: #F8F8F8;
                line-height: 40px;
                border: 1px solid #fff;
            }
            .table-item.header{
                font-weight: 500;
            }
        }
    }
    .subtask-table{
        display: block;
        margin-left: 20px;
        .subtask-table-row{
            display: flex;
            flex-flow: row wrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .subtask-table-row-item-dnd{
                width: 60px;
                color: #474849;
                font-size: 16px;
                letter-spacing: 0;
                padding-left: 14px;
                background-color: #E0E0E0;
                line-height: 40px;
                border: 1px solid #fff;
            }

            .subtask-table-row-item{
                flex-grow: 1;
                flex-basis: 100px;
                color: #474849;
                font-size: 16px;
                letter-spacing: 0;
                padding-left: 14px;
                background-color: #E0E0E0;
                line-height: 40px;
                border: 1px solid #fff;

            }
            .subtask-table-row-item.icon-options{
                width: 50px;
                flex-grow: 0;
                flex-basis: auto;
            }
            .subtask-table-row-item:nth-of-type(2),
            .subtask-table-row-item:nth-of-type(5),
            .subtask-table-row-item:nth-of-type(7){
                display: none;
            }
        }
    }

}
.details.no-flex {
    display: block;
    flex-direction: column;
    min-height: unset;
    max-width: 100%;
    position: static;
}
.details {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    position: relative;

    .main-info-wrapper {

        .main-table-wrapper {
            padding-left: 34px;

            .role-table {

                .table-body-wrapper {

                    .table-row-wrapper {

                        .table-data {
                            background-color: #F8F8F8;
                            border: 1px solid #fff;
                            padding: 11px 14px;
                        }
                    }

                    .table-row-wrapper.selected {

                        .table-data {
                            background-color: #cce3ec;
                            border: 1px solid #cce3ec;
                            padding: 11px 14px;
                        }
                    }

                    .subgrid-table {

                        .table-row-wrapper {

                            .table-data {
                                background-color: rgba(224,224,224, 0.5);

                                &.sub-small-td {
                                    cursor: default;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-action-outer {
    position: relative;
    width: 0px;
    height: calc(100% - 105px);
    margin-top: 76px;


    .table-actions-wrapper {
        position: absolute;
        left: -50px;
        height: 100%;
        overflow-y: auto;
        background-color: rgba(248,248,248,0.85);
        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);

        .table-action-item {
            height: 40px;
            width: 50px;
            cursor: pointer;
            background-color: rgba(248,248,248,0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;

            .custom-small-dropdown-options-list,
            .options-button-options-list {
                position: fixed;
                right: 36px;
                top: initial;
                z-index: 2;
            }

            .custom-small-dropdown-button {
                border: none;
            }

            .icon {
                font-size: 20px;
                color: #474849;
            }
        }
    }
}

.complience-statuses-cell {
    width: 188px;
}

.complience-statuses {
    text-overflow: ellipsis;
    position: relative;

    .tooltip {
        .__react_component_tooltip {
            display: flex;
            flex-direction: column;
            z-index: 10;
            opacity: 1;
            padding: 23px;

            .complience-status-wrapper {
                margin-bottom: 10px;
            }
        }
    }
}

.complience-status-wrapper {
    display: inline-flex;
    margin-right: 17px;
    align-items: center;

    &.complete {
        .complience-count {
            background-color: rgb(67, 145, 10);
        }
    }

    &.waiting-for-approval {
        .complience-count {
            background-color: rgb(0, 122, 255);
        }
    }

    &.invalid {
        .complience-count {
            background-color: rgb(189, 16, 224);
        }
    }

    &.expired {
        .complience-count {
            background-color: rgb(255, 89, 95);
        }
    }

    &.missing {
        .complience-count {
            background-color: rgb(245, 166, 35);
        }
    }

    .complience-count {
        height: 20px;
        width: 20px;
        padding-top: 2px;
        border-radius: 50%;
        background-color: gray;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        margin-right: 5px;
        margin-top: -1px;
    }

    .complience-name {
        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.cell-with-tooltip-wrapper {
    position: relative;

    .cell-with-tooltip-value {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-tooltip-wrapper {
        position: absolute;
        width: 210px;
        max-height: 300px;
        border: 1px solid #e0e0e0;
        z-index: 20;
        background-color: #fff;
        right: -7px;
        top: 150%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);

        .complience-status-wrapper:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}
