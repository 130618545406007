@import './src/common/styles/_mixin.scss';

@mixin rateLavev($b-g-c) {
  height: 20px;
  width: 30px;
  border-radius: 10px;
  background-color: $b-g-c;
  align-items: center;
  justify-content: center;
  @include font(#ffffff, 14px, 800, 18px);
  @include d-flex-items-center;
}

@mixin font($color, $f-s, $f-w, $l-h) {
  color: $color;
  font-size: $f-s;
  font-weight: $f-w;
  letter-spacing: 0;
  line-height: $l-h;
}

@mixin d-flex-items-center {
  display: flex;
  align-items: center;
}

.available-therapist-table {
  .rateLavev {
    @include d-flex-items-center;
    .one {
      @include rateLavev(#34c759);
    }
    .two {
      @include rateLavev(#f5a623);
    }
    .three {
      @include rateLavev(#ff375f);
    }
  }

  .status-active {
    @include d-flex-items-center;
    .active {
      @include font(#009068, 16px, 500, 19px);
    }
  }

  .therapistLink {
    @include d-flex-items-center;
    padding: 0;
    .icon {
      font-size: 24px;
    }
    .ag-react-container {
      justify-content: center;
    }
  }

  .therapist-status {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
  }

  .therapist-checkbox {
    .ag-cell-wrapper {
      width: 100%;
      height: 100%;
      .ag-selection-checkbox {
        width: 100%;
        height: 100%;
        .ag-input-field {
          width: 100%;
          height: 100%;
          .ag-checkbox-input-wrapper {
            background: inherit;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            outline: none;

            .ag-checkbox-input {
              outline: none;
              background: inherit;
              opacity: 1;
              cursor: pointer;

              &::before {
                content: "Add";
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                color: #474849;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
            &::after {
              content: "";
            }
            &.ag-checked {
              .ag-checkbox-input {
                &::before {
                  content: "Added";
                  color: #0192c9;
                }
              }
            }
          }
        }
      }
    }
  }

  .ag-row {
    &.ag-row-hover {
      background-color: #ececec !important;
    }
    &.ag-row-selected {
      background-color: #E5F4F9 !important;
      &.ag-row-hover {
        background-color: #E5F4F9;
      }
    }
  }
}

.available-therapist-header-select {
  .custom-select-display {
    padding: 20px 40px 8px 15px;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 14px;
      font-size: 24px;
      color: #474849;
    }
  }

  &.week {
    .custom-field-outer {
      .custom-select-wrapper {
        width: 230px;
      }
    }

    div {
      .custom-select-description,
      .select-value {
        padding-left: 40px;
      }
    }
  }
}

.zip-code-assigned {
  .base-input-wrapper-with-ico {
    width: 150px;
  }
}

.available-therapist-filter__department {
  .custom-select-wrapper {
    width: 250px;
  }
}

