.blue{
    color: #fff;
    background-color: #0192c9;
    border: none;
}

.white{
    border: 1px solid #e0e0e0;
    color: #474849;
    background-color: #fff;
}

.medium{
    position: relative;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    height: 35px;
    padding: 0 10px;
    align-items: center;
}