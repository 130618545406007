.total-row-bar {
  color: #474849;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.1;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
}
