.ag-center-cols-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.ag-horizontal-left-spacer::-webkit-scrollbar,
.ag-horizontal-right-spacer::-webkit-scrollbar {
    border-top: 1px solid #E0E0E0;
    height: 28px;
}

.ag-center-cols-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.ag-horizontal-left-spacer::-webkit-scrollbar-track,
.ag-horizontal-right-spacer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: none;
    border: solid 10px transparent;
}

.ag-center-cols-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:horizontal,
.ag-horizontal-left-spacer::-webkit-scrollbar-thumb:horizontal,
.ag-horizontal-right-spacer::-webkit-scrollbar-thumb:horizontal {
    box-shadow: inset 0 0 10px 10px #D8D8D8;
    border-top: solid 18px transparent;
    border-bottom: solid 6px transparent;
    border-left: solid 0 transparent;
    border-right: solid 8px transparent;
}

.ag-body-horizontal-scroll-viewport {
    overflow-x: auto;
}

.ag-header.move {
    cursor: move!important;
    .header-column-name {
        cursor: move!important;
    }
    .table-dropdown-filter-main-view-text {
        cursor: move!important;
    }
    .icon-down {
        cursor: move!important;
    }
    .table-dropdown-filter-main-view {
        cursor: move!important;
    }
    .table-dropdown-filter-wrapper {
        cursor: move!important;
    }
    .ag-react-container {
        cursor: move!important;
    }
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover,
.ag-horizontal-left-spacer::-webkit-scrollbar-thumb:hover,
.ag-horizontal-right-spacer::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
}

.ag-full-width-container {
    box-shadow: inset -15px 0 15px -15px rgba(0,0,0,0.5);
}

.ag-header {

    .ag-header-viewport {

        .ag-header-container {
            padding-left: 25px!important;

            .ag-header-cell {
                .ag-react-container {
                    .table-dropdown-filter-wrapper {
                        .icon-down {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.ag-center-cols-clipper {
    .ag-center-cols-viewport {
        .ag-center-cols-container {
            padding-left: 25px!important;
        }
    }
}

.new-table-header-top-bar,
.ag-body-horizontal-scroll,
.ag-status-bar {
    padding-left: 25px!important;
}


.left-shadow {
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
    left: -236px;
    width: 301px;
    pointer-events: none;

    &.active {
        box-shadow: 8px 0 25px -10px rgba(0, 0, 0, 0.5);
    }
}

.left-shadow-absolute {
    position: absolute;
    top: -7px;
    bottom: 0;
    z-index: 5;
    left: -60px!important;
    width: 32px;
    pointer-events: none;

    &.active {
        box-shadow: 8px 0 15px -10px rgba(0, 0, 0, 0.5);
    }
    + .ag-table-main {
        margin-left: -25px!important;
    }
}

@media (max-width: 712px) {
    .left-shadow {
        left: -300px;
        width: 300px;

        &.active {
            top: 175px;
        }
    }
}

.ag-body-viewport {
    &::-webkit-scrollbar {
        height: 28px;
        width: 18px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        border: solid 10px transparent;

    }

    &::-webkit-scrollbar-thumb:vertical {
        box-shadow: inset 0 0 10px 10px #D8D8D8;
        border-top: solid 5px transparent;
        border-left: solid 7px transparent;
        border-bottom: solid 10px transparent;
        border-right: solid 7px transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
    }
 }

.header-column-name {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}

.ag-table {
    height: 100%;
    width: 100%;

    .ag-status-bar {
        padding-left: 0;
        height: 17px;
        border-top: 0;
    }
    .ag-custom-filter-popup {
        overflow: visible;
        & .custom-column-filter-input,
        & .custom-column-filter-input {
            position: relative;
            overflow: hidden;
        }

        & .ag-popup-child:not(.ag-tooltip-custom) {
            box-shadow: none;
        }

        &.ag-menu {
            background-color: #f8f8f8;
        }
        &.ag-menu {
            border: 0;
            border-color: transparent;
            background: transparent;
            border-radius: 3px;
            box-shadow: none;
            padding: 0;
        }
        & .column-filter-options-wrapper {
            margin-top: 8px;
        }
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 0;
        padding-right: 0;
        .ag-react-container {
            display: flex;
            width: 100%;
        }
    }
    .ag-react-container {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .ag-header-cell-label {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
    }

    .ag-header {
        background-color: #FFFFFF;
        border-bottom-color: #FFFFFF;
    }
    .ag-header-cell.ag-column-hover {
        //background-color: #f8f8f8;
    }
    .ag-root-wrapper {
        border-color: #ffffff;
    }
    .ag-pinned-left-header {
        border-right-color: #FFFFFF;
    }
    .ag-pinned-right-header .ag-header-active .ag-header-cell-resize {
        background: #F8F8F8;
    }
    .ag-pinned-right-header .ag-header-active .ag-react-container {
        background: #F8F8F8;
    }
    .ag-pinned-right-header {
       border-left-color: #FFFFFF;
       box-shadow: 5px 2px 15px 1px #cccccc;
    }
    .ag-pinned-right-cols-container {
        transform: translateX(1px);
    }
    .ag-row {
        cursor: pointer;
        color: #000000;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        height: 40px;
        background-color: #F8F8F8;
        border-color: #ffffff;
    }

    .ag-row.ag-row-odd,
    .ag-row.ag-row-even {
        background-color: #F8F8F8;
    }

    .ag-row.ag-row-hover,
    .ag-row.ag-row-odd.ag-row-hover,
    .ag-row.ag-row-odd.ag-row-hover .custom-select-display,
    .ag-row.ag-row-even.ag-row-hover .custom-select-display,
    .ag-row.ag-row-even.ag-row-hover {
        background-color: #E5F4F9;
    }

    .ag-row.ag-row-selected,
    .ag-row.ag-row-selected.ag-row-odd,
    .ag-row.ag-row-selected.ag-row-odd .custom-select-display,
    .ag-row.ag-row-selected.ag-row-even .custom-select-display,
    .ag-row.ag-row-selected.ag-row-even {
        background-color: #ECECEC;
    }

    .ag-cell-range-handle,
    .ag-cell-focus{
        border-color: transparent!important;
    }

    .ag-row-loading {
        margin-left: 25px!important;
    }
    .ag-cell,
    .ag-cell-wrapper {
        border-right-color: #FFFFFF;
        border-left-color: #FFFFFF;
    }

    .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
        border-left-color: #ffffff;
    }
    .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
        border-right-color: #ffffff;
    }

    .ag-pinned-right-cols-container {
        box-shadow: 5px -10px 15px 1px #cccccc;
    }
    .ag-pinned-left-cols-container {
       border-right: 1px solid #cccccc;
    }

    .ag-pinned-left-cols-container {
        .ag-row {
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
            height: 40px;
            background-color: #F8F8F8;
            border-color: #F8F8F8;
        }
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
           border-right-color: #cccccc;
        }
    }
    .ag-pinned-right-cols-container {
        .ag-row {
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
            height: 40px;
            background-color: #F8F8F8;
            border-color: #F8F8F8;
        }
        .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
            border-left: solid 1px;
            border-left-color: #F8F8F8;
        }
    }
}

.ag-table-main {
    height: 1px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: stretch;
    margin-top: 20px;
}

.ag-table-wrapper {
    width: calc(100%);
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    transition: all .3s ease;
}

.ag-table-wrapper.inner {
    & .table-header-top-bar {
        padding: 0 0 16px 0!important;
    }
}

.table-header-top-bar {
    padding: 16px 34px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;

    &__left-section {
        display: inline-flex;
        flex-wrap: wrap;
        row-gap: 10px;

        .field-set {
            width: auto;
        }

        .custom-field-outer {
            margin-right: 15px;
        }

        .base-input-wrapper-with-ico {
            width: 285px;
        }

        .custom-select-wrapper {
            width: 180px;

            .option-list {
                width: 130%;

                &::-webkit-scrollbar {
                    height: 28px;
                    width: 18px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 10px 10px transparent;
                    border: solid 0 transparent;
                }

                &::-webkit-scrollbar-thumb:vertical {
                    box-shadow: inset 0 0 10px 10px #D8D8D8;
                    border-top: solid 10px transparent;
                    border-left: solid 7px transparent;
                    border-bottom: solid 10px transparent;
                    border-right: solid 7px transparent;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
                }
            }
        }
    }

    .cancel-selected-rows{
        display: flex;
        align-items: center;

        .selected-count {
            margin-left: 10px;
        }
    }
}
.ag-cell.d-flex {
    display: flex;
}
.ag-cell.flex-center {
   .ag-react-container {
       justify-content: center;
   }
}
.ag-cell.flex-align-center {
    .ag-react-container {
        align-content: center;
        height: 100%;
    }
}
.no-min-height {
    min-height: unset!important;
}


.none-table-padding {
    .ag-header .ag-header-viewport .ag-header-container {
        padding-left: 0 !important;
    }
    .ag-center-cols-clipper .ag-center-cols-viewport .ag-center-cols-container {
        padding-left: 0 !important;
    }
    .new-table-header-top-bar, .ag-body-horizontal-scroll, .ag-status-bar {
        padding-left: 0 !important;
    }
    .ag-table-main {
        margin-top: 0;
    }
}
.none-table-show {
    .ag-full-width-container {
        box-shadow: none;
    }
}

