.therapist-rate-template {
  display: flex;
  padding-left: 23px;
  border-bottom: 1px solid #e0e0e0;
  .view-field {
    font-family: 'Inter', sans-serif;
    padding: 8px 40px 7px 0;
    margin-bottom: 19px;
  }
  .base-input-label {
    color: #474849;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
  }
  .view-value {
    padding-top: 3px;
    color: #474849;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.therapist-rate-edit-sidebar {
  .right-side-bar-header {
    border-bottom: none;
  }
}

.therapist-rate-update-sidebar{
  .right-side-bar-body {
    padding: 0;
  }
  .edit-rate-section {
    padding: 23px;
  }
  .field-set {
    background: #ffffff;
  }
}

.update-rate {
  background-color: #FBFBFB;
  border: 1px solid #E0E0E0;
  &__header {
    padding: 23px 23px 24px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    color: #474849;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
  &__body {
    padding: 0 23px 23px 23px;
    .base-input-wrapper input#corporateName[disabled] {
      background: #FFFFFF;
    }
  }
}
