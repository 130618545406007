.available-therapist-map {
    .search-area-clear-filter {
        margin-left: 10px !important;
    }
    .map-therapist-filter.collapsed {
        width: 70px!important;
    }
}

.status-label {
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    font-size: 16px;
    color: #0192C9;
}

.therapist-info-footer {
    border-top: 1px solid #E0E0E0;
    padding: 8px 16px 8px 22px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .open-profile {
        margin-left: auto;
    }
}

.map-fix {
    height: 1px!important;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    .map-wrapper {
        position: relative;
        height: 1px;
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        justify-content: stretch;
        .map-therapist-filter-children {
            overflow: auto;
        }
        .therapist-map-bottom {
            width:	100%;
            height: 21px;
            padding-top: 7px;
            margin-bottom: 0;
        }
    }
    .map-body {
        flex: 1;
    }
    .map-therapist-filter {
        padding: 0 10px 0 34px!important;
    }

    .number-field {
        margin-bottom: 14px;
    }

    .week {
        .custom-field-outer {
            .custom-select-wrapper {
                width: 100%!important;
            }
        }
    }
}
