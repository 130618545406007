.individual-days-wrapper {
    width: 100%;
    .row-two-column {
        &.edges {
            margin-top: 14px;
            .custom-field-outer {
                width: 48%;
            }
            > .native-time-picker {

                &:nth-child(1) {
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    margin-left: 10px;
                }
            }
            .native-time-picker {
                width: 100%;

                .custom-field-outer{
                    width: 100%;
                }
            }
        }
    } 
}
