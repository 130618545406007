.icon-button {
  margin: 0px 8px 0px 8px;
  background:#FFFFFF;
  border: 1px solid #E0E0E0;
  height: 34px;
  width: 34px;
  font-size: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f3f2f2;
  }
}
