.school-status {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  padding: 2px 4.5px;
  text-transform: uppercase;
  background-color: rgba(248, 248, 248, 0.4);

  &.fixed-width {
    display: inline-block;
    min-width: 70px;
  }

  &.complete {
    background-color: rgb(35, 87, 2);
  }

  &.new {
    background-color: rgb(0, 122, 255);
  }

  &.pendinginterview {
    background-color: rgb(189, 16, 224);
  }

  &.accepted {
    background-color: #43910a;
  }

  &.pendingauth,
  &.awaiting-auth {
    background-color: rgb(189, 16, 224);
  }

  &.declined {
    background-color: rgb(255, 89, 95);
  }

  &.partiallycovered,
  &.pendinglegacy,
  &.open {
    background-color: #f5a623;
  }

  &.inprogress,
  &.waiting {
    background-color: #474849;
  }

  &.assigned {
    background-color: #d58f0a;
  }

  &.declined,
  &.closed {
    background-color: rgb(255, 89, 95);
  }
}


.school-name-autocomplete {
  .option-list {
    overflow: auto;
    white-space: nowrap;
    width: 553px;
    padding-top: 0;
  }
  .school-names-table {
    display: table;
  }
  .school-names {
    display: table-row;
    flex-wrap: nowrap;
    cursor: pointer;
    width: max-content;
    min-width: 100%;
    &:hover {
      background-color: #E5F4F9;
    }
    > div {
      display: table-cell;
      flex-wrap: nowrap;
      padding: 10px 10px 10px 10px;
      border-right: 1px solid #ccc;
    }
    > div:last-child {
      flex: 1 auto;
      border-right:0;
    }
  }

  .school-headers {
    background-color: #fff;
    font-size: 14px;
    display: table-row;
    flex-wrap: nowrap;
    width: max-content;
    min-width: 100%;
    position: sticky;
    top: 0;

    > div {
      display: table-cell;
      flex-wrap: nowrap;
      padding: 15px 5px 10px 5px;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    > div:last-child {
      flex: 1 auto;
      border-right:0;
    }
  }
}

