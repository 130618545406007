.loadingSpinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #0192C9;
    border-radius: 50%;
    border-top-color: #E5F4F9;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }