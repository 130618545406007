.entity-rates-files-list {
    &::before {
        content: 'View';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        outline: none;
        height: 35px;
        padding: 0 10px;
    }

    .custom-select-wrapper {
        position: relative;
        right: -50px;

        .custom-select-display {
            background-color: initial !important;
        }
    }

    .select-value {
        margin-left: -56px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-dropdown {
            position: absolute;
            top: 50%;
        }
    }
}

.rates-table-wrapper {
    .ag-row-level-1 {
        background-color: #ECECEC !important;

        .arrow-cell {
            background-color: #fff;
        }

        .dots-menu-cell {
            pointer-events: none;
            background-color: #fff;

            .ag-react-container {
                display: none;
            }
        }
    }

    .dots-menu-cell {
        position: relative;

        .custom-select-wrapper {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}