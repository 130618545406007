@import './src/common/styles/_mixin.scss';

.therapist-assign-dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    top: 14%;

    .table-page {
        min-height: 100%;

        header {
            padding-top: 18px;
            .rh-info {
                &__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                &__body-info {
                    display: flex;
                    align-items: center;
                    > span:first-child {
                        width: 55%;
                    }
                }
                &__caption {
                    margin-left: 10px;
                    @include font(#474849, 14px, 800, 18px);
                    margin-right: 10px;
                    :first-child {
                        margin-left: 0;
                    }
                }
                &__instructionBody {
                    margin-top: -45px;
                    width: 45%;
                    padding-right: 190px;
                    word-break: break-word;
                }
                &__instructionTitle {
                    display: block;
                    padding-bottom: 5px;
                    @include font(#474849, 14px, 800, 18px);
                }
            }
            .therapist-assign-dialog__right {
                display: flex;
                flex: 1;
                justify-content: flex-end;

                > button {
                    margin-left: 14px;
                }
            }
        }
    }
}

.therapist-assign-dialog-backgroud {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 998;
    background: rgba(216, 216, 216, 0.3);
}

.scroll-hidden {
    .content {
        overflow: hidden !important;
    }
}
