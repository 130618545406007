.filter-checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    padding: 5px;
    min-width: 120px;
    height: 50px;
    margin-top: 14px;
    margin-right: 16px;

    span {
        color: #474849;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        text-transform: uppercase;
    }

    div {
        height: 25px;
    }

    &:hover {
        cursor: pointer;
    }
}