.school-request-place-of-service-card {
    .view-field {
        padding-top: 0 !important;
        padding-left: 0 !important;
    }
}


.school-grid-wrapper {
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    width: 100%;
    max-width: 1550px;
    row-gap: 10px;
    gap: 10px;
    > div {
        margin: 0;
    }

    .box-1 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: 1;
        min-width: 300px!important;
    }

    .box-2 {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
    }
    .box-4 {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 5;
        min-width: 690px;
    }
}

.school-grid-wrapper.lg-card {
    grid-template-columns:  repeat(3, 1fr);
    max-width: 1164px;
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 100;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    .box-2 {
        width: 100%;
        max-width: 100%;
        grid-column-end: 7;
    }
    .box-4 {
        grid-row-start: 2;
        grid-row-end: 30;
        grid-column-start: 2;
        grid-column-end: 7;
        min-height: 100%;
        min-width: 752px;
        max-width: 100%;
    }
}
.school-grid-wrapper.md-card {
    grid-template-columns:  repeat(3, 1fr);
    max-width: 1164px;
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 100;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    .box-2 {
        width: 100%;
        max-width: 100%;
        grid-column-end: 7;
    }
    .box-4 {
        grid-row-start: 2;
        grid-row-end: 30;
        grid-column-start: 2;
        grid-column-end: 7;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }
}
.school-grid-wrapper.sm-card {
    grid-template-columns:  repeat(2, 1fr);
    .box-1 {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        grid-row-end: 3;
    }
    .box-2 {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-4 {
        grid-row-start: 3;
        grid-row-end: 12;
        grid-column-start: 1;
        grid-column-end: 4;
        min-width: 100%;
        margin-top: 0;
        min-height: 230px;
    }
}
.school-grid-wrapper.extra-sm-card {
    grid-template-columns:  repeat(1, 1fr);
    .box-1 {
        grid-row-end: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-2 {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-4 {
        grid-row-start: 3;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
        min-width: 100%;
        margin-top: 0;
        min-height: 230px;
    }
}
.school-general-tab {
    .tabs-nav {
        max-width: 170px!important;
        min-width: 170px!important;
    }
    .tabs-active-content {
        padding-left: 24px!important;
        padding-right: 12px;
    }
}
