.avatar-wrapper {
    background: #F5F5F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-initials {
        color: #474849;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-transform: uppercase;
    }

    img {
        border-radius: 50%;
        width: 100%;
        object-fit: cover;
    }
}
