.patient-basic-info {
  position: relative;

  &__btn-edit {
    position: absolute;
    right: 15px;
    top: -20px;
  }
}

.patients-card {
  max-width: 373px;
  margin-bottom: 0;

  &.patients-card-left,
  &.patients-card-right {
    max-width: 420px;
  }

  .card__body {
    padding-bottom: 0;
  }

  @media screen and (max-width: 768px){
    .card__item {
      width: 100%!important;
    }
  }
}

.create-patients-form {
  .base-input-label {
    max-width: calc(100% - 59px);
  }
}
