@import "icomoon-variables.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon/icomoon.woff') format('woff'),
    url('../fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-admin {
  &:before {
    content: $icon-admin;
  }
}
.icon-authorization {
  &:before {
    content: $icon-authorization;
  }
}
.icon-back {
  &:before {
    content: $icon-back;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-cellphone {
  &:before {
    content: $icon-cellphone;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-data {
  &:before {
    content: $icon-data;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-down {
  &:before {
    content: $icon-down;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-export {
  &:before {
    content: $icon-export;
  }
}
.icon-facility {
  &:before {
    content: $icon-facility;
  }
}
.icon-favourite {
  &:before {
    content: $icon-favourite;
  }
}
.icon-invoice {
  &:before {
    content: $icon-invoice;
  }
}
.icon-job {
  &:before {
    content: $icon-job;
  }
}
.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-open-in {
  &:before {
    content: $icon-open-in;
  }
}
.icon-options {
  &:before {
    content: $icon-options;
  }
}
.icon-people {
  &:before {
    content: $icon-people;
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-signature {
  &:before {
    content: $icon-signature;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-service {
  &:before {
    content: $icon-service;
  }
}
.icon-show {
  &:before {
    content: $icon-show;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-sub {
  &:before {
    content: $icon-sub;
  }
}
.icon-technician {
  &:before {
    content: $icon-technician;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-upload-file {
  &:before {
    content: $icon-upload-file;
  }
}
.icon-user-management {
  &:before {
    content: $icon-user-management;
  }
}
.icon-WiFi {
  &:before {
    content: $icon-WiFi;
  }
}
.icon-golas {
  &:before {
    content: $icon-golas;
  }
}
.icon-launch {
  &:before {
    content: $icon-launch;
  }
}
.icon-switch {
  &:before {
    content: $icon-switch;
  }
}
.icon-tasks {
  &:before {
    content: $icon-tasks;
  }
}
.icon-walk {
  &:before {
    content: $icon-walk;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-talk {
  &:before {
    content: $icon-talk;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-logging {
  &:before {
    content: $icon-logging;
  }
}
.icon-notes {
  &:before {
    content: $icon-notes;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera;
  }
}
.icon-eye-on {
  &:before {
    content: $icon-eye-on;
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off;
  }
}
.icon-marker {
  &:before {
    content: $icon-marker;
  }
}
.icon-loop {
  &:before {
    content: $icon-loop;
  }
}
