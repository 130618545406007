@import '../fonts/icomoon-variables.scss';

body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;

    * {
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;

        a {
            color: initial;
            text-decoration: none;
        }
    }
}

.content {
    overflow-y: auto;
    transition: 0.3s linear width;
    width: calc(100% - 65px);

    &.extended-content {
        transition: 0.3s linear width;
    }

    &.technician-content {
        padding: 25px;
        width: 100%;
        margin-left: 0;
    }
}
.mb-29 {
    margin-bottom: 29px;
}
.mb-13 {
    margin-bottom: 29px;
}
.pl-25 {
    padding-left: 25px;
}

.content-flex {
    display: flex;
    flex: 1;
}

header {
    .header-wrapper {
        border-bottom: 1px solid #E0E0E0;
        position: relative;
        padding: 24px 24px 0 34px;
        border-bottom: none!important;
        height: unset;
        display: flex;

        h1 {
            color: #474849;
            font-size: 30px;
            font-weight: 500;
            line-height: 37px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .settings-section {
            position: absolute;
            bottom: 20px;
            width: 100%;
            display: flex;
        }

        .mobile-settings-section {
            display: none;
        }

        img {
            display: none;
        }

        .title-wrapper {
            display: flex;
        }
    }

    .header-filter-select-wrapper {
        width: 200px;
        margin-right: 15px;

        .custom-select-wrapper {
            .option-list {
              width: 130%;
            }
        }
    }
}

.main-spinner-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;

    div {
        width: 70px;
        height: 70px;
    }
}

.nps-checkbox, .nps-table-checkbox {
	position: absolute;
    z-index: -1;
    opacity: 0;
}
.nps-checkbox + label, .nps-table-checkbox + label {
    display: inline-block;
	position: relative;
    cursor: pointer;
    height: 19px;
    width: 19px;
    border: 1px solid #E0E0E0;

    &:after {
        position: absolute;
        content: $icon-check;
        font-family: 'icomoon';
        color: #E0E0E0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.nps-checkbox + label {
    pointer-events: none;
}

.nps-checkbox:checked + label, .nps-table-checkbox:checked + label {
    background-color: #0192C9;
    border-color: #0192C9;

    &::after {
        color: #fff;
    }

    span {
        color: #0192C9;
        font-weight: 500;
    }
}

.select-wrapper {
    width: 200px;
    height: 50px;
    border: 1px solid #E0E0E0;
    flex-direction: column;
    padding: 8px 15px;
    color: #474849;
    position: relative;
    margin-right: 15px;

    .select-disp {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        cursor: pointer;
    }

    .select-description {
        font-size: 10px;
        font-weight: 700;
        line-height: 13px;
        text-transform: uppercase;
        margin-bottom: 2px;
    }

    .select-value {
        font-size: 16px;
        line-height: 19px;
    }

    .option-list {
        position: absolute;
        width: 271px;
        max-height: 350px;
        min-height: max-content;
        border: 1px solid #E0E0E0;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
        top: 102%;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 0px;

        .option-item {
            padding: 11px 20px;
            cursor: pointer;

            span {
                margin-left: 30px;
                display: inline-block;
                white-space: nowrap;
            }

            &:hover {
                background-color: #E5F4F9;
            }
        }
    }

    &:after {
        content: $icon-dropdown;
        position: absolute;
        font-family: 'icomoon';
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        pointer-events: none;
    }
}

.main-filter-input-wrapper {
    width: 285px;
    margin-right: 15px;
}

.main-info-wrapper {
    display: flex;

    .main-table-wrapper {
        height: 100%;

        .role-table {
            height: 100%;

            .table-body-wrapper {
               height: calc(100% - 50px);
            }
        }
    }
}

.header-button-wrapper {
    margin-left: auto;
}

.header-create-button {
    padding: 0 10px;
    height: 34px;
    background-color: #0192C9;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.table-dropdown-filter-wrapper {
    .icon-down {
        font-size: 21px;
        color: #9e9e9e;
        font-family: 'icomoon';
        position: absolute;
        top: 50%;
        right: 3px;
        transform: translateY(-50%);
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.to-top {
            transform: translateY(-50%) rotateX(180deg);
            background-color: #E5F4F9;
            color: #0192C9;
        }
        &.to-bottom {
            transform: translateY(-50%) rotateX(0deg);
            background-color: #E5F4F9;
            color: #0192C9;
        }

        &:hover {
            background-color: #E5F4F9;
            color: #0192C9;
        }
    }
}


// default buttons
.default-small-btn-with-icon {
    border: 1px solid #E0E0E0;
    width: 35px;
    height: 35px;
    font-family: "icomoon";
    color: #474849;
    font-size: 25px;
    background-color: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;

    &.active-btn,
    &:hover {
        background-color: #f5f5f5;
    }

    &:active {
        background-color: #E0E0E0;
    }

    &.blue {
        background-color: #0192C9;
        color: #fff;
        border: none;
    }
}

.base-text-btn {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    height: 35px;
    padding: 0px 14px;
    display: flex;
    align-items: center;

    .icon:first-child {
        margin-right: 8px;
    }

    &.white {
        border: 1px solid #E0E0E0;
        color: #474849;
        background-color: #fff;

        &:hover {
            background-color: #f5f5f5;
        }

        &:active {
            background-color: #E0E0E0;
        }
    }

    &.blue {
        border: none;
        color: #fff;
        background-color: #0192C9;

        &:hover {
            background-color: #34a8d4;
        }

        &:active {
            background-color: #80c8e4;
        }
    }

    &:disabled {
        border: none;
        background-color: #E0E0E0;
        pointer-events: none;
        color: #474849;
    }

    &.export-btn {
        padding-right: 25px;

        &:after {
            content: $icon-down;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            color: #474849;
            font-family: 'icomoon';
        }
    }
}

.base-text-button-with-icon {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    height: 35px;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    color: #474849;
    background-color: #fff;

    .icon {
        margin-right: 10px;
        font-size: 20px;
    }

    &:hover {
        background-color: #f5f5f5;
    }

    &:active {
        background-color: #e0e0e0;
    }
}

.base-transparent-text-button {
    height: 35px;
    padding: 0 15px;
    background-color: initial;
    border: none;
    font-size: 16px;
    cursor: pointer;
    outline: none;

    &.gray {
        color: #474849;
        font-weight: 500;
    }

    &.blue {
        color: #0192C9;
        font-weight: 500;
    }
}


.completed {
    &.base-text-button-with-statuses {
        &__bottom {
            color: #0192C9;
        }
    }
}

.base-text-button-with-statuses {
    height: 63px;
    width: 344px;
    border: 1px solid #E8E9EA;
    background-color: initial;
    outline: none;
    display: flex;
    flex-direction: column;
    padding: 14px 14px 10px;
    color: #474849;

    &__top {
        display: flex;
        align-items: center;
        .icon {
            font-size: 20px;
            margin-right: 10px;
        }

        .btn-name {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 6px;
        }
    }

    &__bottom {
        margin-left: 30px;
        font-size: 10px;
        line-height: 13px;

        &.completed {
            color: #0192C9;
        }

        &.uncompleted {
            color: #FC6767;
        }
    }
}

// default fields
.default-field-read-only-with-icon {
    height: 50px;
    display: flex;
    align-items: center;
    color: #474849;

    .default-field-read-only-icon {
        font-family: "icomoon";
        font-size: 25px;
        margin-right: 10px;
    }

    .default-field-read-only-with-icon-values {
        display: flex;
        flex-direction: column;

        .default-field-read-only-with-icon-title {
            &.field-with-data {
                font-size: 10px;
                font-weight: 600;
                line-height: 13px;
                text-transform: uppercase;
            }
        }
    }
}

// right sidebar
.right-side-bar-tabs-wrapper {
    height: 60px;

    .right-side-bar-tab {
        font-weight: 600;
        height: 100%;
        margin-right: 40px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &.right-side-bar-active-tab {
            border-bottom: 2px solid #0192C9;
            color: #0192C9;
        }
    }
}

.react-datepicker-popper {
    z-index: 2;

    .react-datepicker {

        &__navigation {
            outline: none;
        }
    }
}

.react-confirm-alert-svg {
    display: none;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;

    .dialog-confirmation {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #E0E0E0;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
        width: 370px;
        padding: 20px;
        z-index: 100001;

        h1 {
            font-weight: 500;
            text-align: center;
            display: block;
            margin-bottom: 20px;
        }

        .confirmatiion-dialog-cancel-btn,
        .confirmatiion-dialog-confirm-btn {
            display: inline-block;
            width: calc(50% - 10px);
        }

        button {
            &:first-of-type {
                margin-right: 20px;
            }
        }
    }

    .confirmation-dialog-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100000;
        background-color: #000;
        opacity: 0.3;
    }
}

// labels
.sessionDuration {
    background-color: #9E9E9E;
    color: #fff;
    height: 16px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 80px;
}

//required-field-star
.required-field-star {
    &::before {
        position: absolute;
        content: "*";
        left: -15px;
        top: 20px;
        color: #e94a4a;
        font-size: 20px;
    }
}
.row-two-column {
    .required-field-star {
        &::before {
            left: -11px;
        }
    }

    &.edges {
        justify-content: space-between;

        .two-column {
            width: 47%;
        }
    }
}

.field-set {

    &.edit-field {
        position: relative;
    }
}


.autocomplete-one-border {
    outline: none;
    .base-input-wrapper {
        border: none;
    }
}

@media (max-width: 1700px) {
    .therapist-page {

        .table-header-top-bar {
            flex-wrap: wrap;

            .table-header-actions {
                margin: 16px 0 0;

                .options-button-wrapper {

                    .export-btn {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 712px) {
    header {
        .header-wrapper {
            .title-wrapper {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 34px;
                    margin-right: 15px;
                }
            }

            .mobile-settings-section {
                display: flex;
                position: absolute;
                bottom: 20px;

                .mobile-header-filter {
                    position: relative;
                    height: 35px;
                    width: 35px;
                    border: 1px solid #E0E0E0;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'icomoon';
                    font-size: 20px;
                    background-color: initial;
                }

                .mobile-filter-wrapper {
                    position: fixed;
                    background-color: #fff;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 100;

                    .mobile-filter-header {
                        border-bottom: 1px solid #E0E0E0;

                        .buttons-wrapper {
                            display: flex;
                            justify-content: space-between;

                            .mobile-filter-btn {
                                border: none;
                                font-family: 'Inter', sans-serif;
                                font-size: 16px;
                                background-color: initial;
                                padding: 16px;
                                font-weight: 500;

                                &.cancel-btn {
                                    color: #474849;
                                    opacity: 0.5;
                                }

                                &.save-btn {
                                    color: #0192C9;
                                }
                            }

                            span {
                                padding: 16px;
                                font-weight: 500;
                            }
                        }

                        .input-wrapper {
                            padding: 0px 16px 16px 16px;
                            position: relative;

                            .mobile-filter-input {
                                height: 35px;
                                width: 100%;
                                border: 1px solid #E0E0E0;
                                border-left: 2px solid #0192C9;
                                outline: none;
                                font-size: 16px;
                                padding: 0px 16px 0px 30px;
                            }

                            .icon-search {
                                font-family: 'icomoon';
                                position: absolute;
                                left: 25px;
                                top: 8px;
                                font-size: 20px;
                                color: #343D41;
                                opacity: .7;
                            }
                        }
                    }

                    .mobile-filter-body {
                        height: calc(100% - 100px);
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        padding: 10px 0;

                        .option-item {
                            padding: 11px 20px;
                            cursor: pointer;

                            span {
                                margin-left: 30px;
                                display: inline-block;
                                white-space: nowrap;
                            }

                            &:hover {
                                background-color: #E5F4F9;
                            }
                        }
                    }
                }
            }

            .settings-section {
                display: none;
            }

            .search-filter-outer {
                position: absolute;
                bottom: 20px;
                width: calc(100% - 68px);
                height: 35px;
                z-index: 100;
                background-color: #fff;

                .search-filter-inner {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    border: 1px solid #E0E0E0;
                    border-left: 2px solid #0192C9;

                    &:before {
                        content: $icon-search;
                        font-family: 'icomoon';
                        position: absolute;
                        left: 10px;
                        font-size: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        padding: 0 35px;
                        border: none;
                        outline: none;
                        font-size: 16px;
                    }

                    .icon-close {
                        font-family: 'icomoon';
                        font-size: 20px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 15px;
                    }
                }
            }
        }
    }

    .left-sidebar-menu {
        left: -65px;
    }

    .content {
        width: 100%;

        &.extended-content {
            left: 0;
            width: 100%;
        }
    }

    .table-info-wrapper {
        .table-info-extend-button {
            display: none;
        }

        .table-info-scroll-wrapper {
            position: relative;
            top: 65px;
        }
    }
 }

 .right-sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.object-link:hover {
    color: #0192c9;
    cursor: pointer!important;
}
.card__item--value,.view-field{
    &.object-link-with-icon {
        cursor: pointer;
        position: relative;
    }

    &.object-link-with-icon:hover{
        background: #F5F5F5;
    }

    &.object-link-with-icon:hover:after{
        content: '\e929';
        position: absolute;
        font-family: 'icomoon';
        display: block;
        font-size: 24px;
        font-weight: normal;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}
