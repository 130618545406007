.data-pagination {
  &__header {
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #474849;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    border-bottom: 1px solid #E0E0E0;
  }
  &__back {
    margin: 13px 12px 13px 13px;
    font-size: 24px;
    cursor: pointer;
  }
  &__counter {
    min-width: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &__next {
    cursor: pointer;
    margin: 13px 13px 13px 12px;
    font-size: 24px;
    transform: rotate(180deg);
    display: inline-block;
  }
  &__body {
    width: 100%;
    min-height: 124px;
    max-height: 209px;
    display: flex;
    flex-direction: column;
  }
  .base-text-btn {
    margin-left: 7px;
  }
}



