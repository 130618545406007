.back-button {
  position: absolute;
  margin-left: -40px;
  margin-top: -48px ;
  transform: translate(0, -100%);
  span.icon {
    margin: 7.5px 10px 7.5px 0;
  }
}
.emails-sent {
  color: #474849;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}
.follow-inbox {
  color: #474849;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.sent-email__check {
  color: #43910A;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}
.forgot-password {
  .error-message-for-input {
    color: #FF595F;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
  }
}
