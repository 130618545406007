.tooltip-compliance-status {
  .cell-with-tooltip-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-tooltip-lite  {
    min-width: 210px;
    max-height: 300px;
    border: 1px solid #e0e0e0;
    background-color: #fff!important;
    padding: 15px!important;
    display: flex!important;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    margin-left: -15px;
    white-space: nowrap;
    color: #474849;
    font-size: 16px;
    font-weight: 400;
  }

  .complience-status-wrapper:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.assigned-sub-department{
 .custom-select-wrapper {
   width: 203px!important;
 }
}

.therapist-list {
  .table-header-top-bar {
    padding-top: 0;
  }

  .table-header-actions {
    align-items: flex-start;
  }

  #ProvidersClearFilters {
    min-width: 115px;
  }

  .table-header-top-bar__left-section {
    align-items: flex-end;

    & > * {
      height: fit-content;
    }

    .new-hires {
      border: 1px solid #E0E0E0;
      display: inline-flex;
      align-items: flex-end;
      flex-wrap: wrap;
      min-height: 70px!important;
      width: 800px;
      margin-right: 15px;
      padding: 0 15px 10px;

      .hiring-days-input {
        .base-input-wrapper {
          width: 180px;
        }
      }

      .title {
        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
      }

      & > *:not(:first-child) {
        flex: 1;
      }

      & > *:last-child {
        max-width: fit-content;

        .custom-field-outer {
          margin-right: 0!important;
        }
      }
    }
  }
}
