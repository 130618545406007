@mixin color-notes ($background-color, $right, $top) {
    content: '';
    position: absolute;
    top: $top;
    right: $right;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $background-color;
    display: flex;
  }

.left-sidebar-menu {
    width: 65px;
    transition: 0.3s linear width;
    position: relative;
    z-index: 5;
    border-right: 1px solid #E0E0E0;
    background-color: #fff;

    .sidebar-nav {
        display: flex;
        flex-direction: column;
        height: calc( 100% - 299px );
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 28px;
            width: 18px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 10px 10px transparent;
            border: solid 10px transparent;

        }

        &::-webkit-scrollbar-thumb:vertical {
            box-shadow: inset 0 0 10px 10px #D8D8D8;
            border-top: solid 5px transparent;
            border-left: solid 7px transparent;
            border-bottom: solid 10px transparent;
            border-right: solid 7px transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
        }

        span {
            height: 20%;
            color: #474849;
            font-weight: 500;
            position: relative;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s linear justify-content;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 0.3s linear justify-content;
                outline: none;

                span {
                    opacity: 0;
                    position: absolute;
                }

                &:hover {
                    background-color: #E5F4F9;
                }
            }
        }

        i {
            font-family: 'icomoon';
            font-style: normal;
            font-size: 24px;
            color: #474849;
        }

        .sidebar-active-item {
            color: #0192C9 ;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                height: 100%;
                width: 3px;
                background-color: #0192C9;
                animation: anima 0.2s linear forwards;
            }

            i {
                color: #0192C9;
            }
        }
        .single-menu-main-item-wrapper {
            display: flex;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;
            height: 60px;
            position: relative;
            i {
                margin-right: 12px;
            }
            .navigation-item {
                display: flex;
                height: 60px;
                align-items: center;
            }
            .navigation-item-content {
                display: none;
                font-size: 16px;
            }
            .ab-icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                font-weight: 600 !important;
                width: 24px;
                height: 24px;
            }
            .ab-icon-1 {
                font-size: 22px;
            }
            .ab-icon-2 {
                font-size: 20px;
            }
            span {
                font-weight: 400;
            }
        }

        .notes-menu-item-wrapper {
            display: flex;
            align-items: center;

            .navigation-item {
                display: flex;
                align-items: center;
                width: 100%;
                height: 60px;
                padding: 0 20px;
                position: relative;

                .icon {
                    font-size: 23px;
                    width: initial;
                    position: initial;
                    margin-right: 12px;
                }
            }
        }
    }

    .nav-bar-button {
        position: absolute;
        width: 100%;
        bottom: 50px;
        height: 50px;
        border-top: 1px solid #E0E0E0;
        cursor: pointer;
        opacity: 0;
        transition: 0.2s ease opacity;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        background: #fff;

        &.logout {
            bottom: 50px;
            background: #ffffff;

            .logout-icon-wrapper {
                display: flex;
                align-items: center;

                .icon-log-out {
                    transform: rotate(180deg);
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }

    .show-hide-extend-left-sidebar {
        background: #ffffff;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 50px;
        border-top: 1px solid #E0E0E0;
        cursor: pointer;
        background: #ffffff;

        div {
            padding: 20.5px 20.5px 17.5px;
            position: relative;
            height: 100%;

            &::after {
                content: '';
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%) rotate(-135deg);
                transition: 0.3s linear transform;
                display: block;
                border: 2px solid #474849;
                border-top: none;
                border-right: none;
                width: 10px;
                height: 10px;
            }

            span {
                color: rgba(71,72,73,0.5);
                font-size: 10px;
                font-weight: 700;
                line-height: 13px;
                opacity: 0;
                text-transform: uppercase;
                position: absolute;
            }
        }
    }

    .expanded {
        opacity: 1;
    }
}

.navigation-bar {

    .navigation-item-content {
        display: flex;
        align-items: center;

        .menu-item-notes {
            display: none;
        }

        .green {

            &::after {
                display: inherit;
                @include color-notes(green, -2px, -2px );

            }
        }

        .red {

            &::after {
                display: inherit;
                @include color-notes(#FF595F , -2px, -2px );
            }
        }
    }


    &.expanded {
        .single-menu-main-item-wrapper {
            .navigation-item-content {
                display: inline-flex;
            }
            .ab-icon {
                margin-right: 12px;
            }
        }
        .navigation-item-content {

            .menu-item-notes {
                font-weight: 400;
                font-size: 16px;
                display: flex;
                align-items: center;
            }

            .green {

                &::after {
                    display: inherit;
                    @include color-notes(green,  -62px, -4px );

                }
            }

            .red {

                &::after {
                    display: inherit;
                    @include color-notes(#FF595F, -62px, -4px );
                }
            }
        }
    }

    .notes-menu-item-wrapper {

        .navigation-item {

            .icon-notes {
                font-size: 23px;
                margin-right: 12px;
            }
        }
    }
}

.switch-mode{
    color: #474849;
    i {
        font-family: 'icomoon';
        font-style: normal;
        font-size: 15px;
        color: #474849;
    }
}

@keyframes anima {
    from{
        height: 0%;
        bottom: 50%;
    }
    to {
        bottom: 0;
        height: 100%;
    }
}

@media (max-width: 712px) {
    .left-sidebar-menu {
        left: -65px;
        position: absolute;
    }
}
