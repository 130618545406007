.settings {
    .tabs-container {
        display: flex;
    }
    header {
        .header-wrapper {
            height: auto;
            padding: 24px 34px 24px 34px;
            h1 {
                margin: 0px 0px 0px 0px;
            }
        }
        padding-bottom: 30px;
    }
    .tabs.vertical {
        margin-top: 0;
    }

    .settings-navigation-tab {
        .title {
            font-size: 18px;
        }
        .tabs.vertical .tabs-active-content {
            width: 100%;
        }
    }
    .info-input-block {
        border-bottom: 1px solid #e0e0e0;
        margin-top: 16px;
        width: 100%;
        padding-bottom: 16px;

        .comment {
            opacity: 0.7;
            color: #474849;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            font-family: 'Inter', sans-serif;
            text-transform: uppercase;
            margin: 14px 0px 17px 0px;
        }
        .days-input {
            margin: 0px 0px 0px 0px;
            width: 65px;
        }
        .required-fields {
            max-width: 300px;
        }
    }
}
