
.tabs{
    margin-top: 0;

    &.horizontal{
        display: flex;
        flex: 1;
        flex-direction: column;

        > ul {

                &.tabs-nav {

                    &.nav {

                        &.navbar-nav {
                            padding: 0 34px;
                            border-bottom: 1px solid #e0e0e0;

                            &.navbar-left {
                                margin: 0;

                                li.tab:not(:last-child) {
                                    margin-right: 40px;
                            }
                        }
                    }
                }
            }
        }

        li.tab {
            display: inline-block;
            padding: 20px 0;
            cursor: pointer;
        }

        li.tab.hide {
            display: none;
        }

        button.tab-link.custom-link {
            background-color: transparent;
            border: none;
            color: #474849;
            cursor: pointer;
            height: 30px;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            text-align: center;
            display: flex;
            align-items: center;
        }

        li.tab.active {
            border-bottom: 1px solid #0192C9;
            button.tab-link.custom-link {
                color: #0192C9;
            }
        }

        .tabs-active-content {
            display: flex;
            flex: 1;
            > div:first-child:not(.right-side-bar) {
                width: 100%;
            }
        }
    }

    &.vertical{
        display: flex;
        flex: 1;
        margin-top: 35px;

        ul {

            &.tabs-nav {

                &.nav {

                    &.navbar-nav{
                        padding: 0 0 0 34px;
                        border-bottom: none;

                        &.navbar-left {
                            width: 30%;
                            display: flex;
                            flex-direction: column;
                            position: relative;

                            li {
                                &.tab {
                                    margin-right: 0;
                                    height: 48px;
                                    padding: 0;
                                    cursor: pointer;
                                    background-color: #f8f8f8;
                                    border: 1px solid #fff;

                                    .custom-link {
                                        padding-left: 18px;
                                        display: flex;
                                        align-items: center;
                                        height: 100%;
                                        font-weight: 400;
                                        background-color: transparent;
                                        border: none;
                                    }
                                    &.has-icon{
                                        padding-left: 30px;
                                        position: relative;
                                        .icon{
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(0%, -50%);
                                            left: 12px;
                                            font-size: 24px;
                                        }
                                    }
                                }

                                &.active {
                                    border: none;
                                    background-color: #E5F4F9;
                                    position: relative;

                                    &:before {
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        top: 0;
                                        width: 2px;
                                        height: 100%;
                                        background-color: #0192C9;
                                    }


                                }
                            }
                        }
                    }
                }
            }
        }

        .tabs-active-content {
            width: fit-content;

            .tabs {

                &.horizontal {

                    .tabs-nav {

                        &.nav {

                            &.navbar-nav {
                                padding: 0 34px;
                                border-bottom: 1px solid #e0e0e0;

                                &.navbar-left {
                                    display: flex;
                                    flex-direction: row;
                                    width: 100%;

                                    .tab {
                                        background: none;

                                        &:not(:last-child) {
                                            margin-right: 40px;
                                        }

                                        &.active {
                                            border-bottom: 1px solid #0192C9;

                                            .tab-link.custom-link {
                                                color: #0192C9;
                                            }

                                            &:before {
                                                content: '';
                                                background: none;
                                            }
                                        }

                                        .tab-link {

                                            &.custom-link {
                                                color: #474849;
                                                height: 30px;
                                                font-size: 16px;
                                                font-weight: 600;
                                                line-height: 30px;
                                                display: block;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
