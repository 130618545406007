.tabs {

        &.vertical {

            .tabs-active-content {
                width: 100%;
                padding-left: 34px;
            }

            .tabs-nav {

                &.nav {

                    &.navbar-nav {

                        &.navbar-left {
                           max-width: 260px;
                        }
                    }
                }
            }
        }
    }


.bio-status {
    margin-left: 10px;
    padding: 2px 4.5px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #FFFFFF;

    &.active{
        background-color: #009068;
    }

    &.archived {
        background-color: #f5a623;
    }
}

.row-two-column {
    display: flex;

    .view-field {
        min-width: 165px;
    }
}
