.map-cluster {
  font-family: 'Inter', sans-serif;
  background-color: rgba(255, 255, 255, 0.6);
  padding-bottom: 30px;
  max-height: calc(100% - 51px)!important;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 28px;
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 10px transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    box-shadow: inset 0 0 10px 10px #D8D8D8;
    border-top: solid 5px transparent;
    border-left: solid 7px transparent;
    border-bottom: solid 10px transparent;
    border-right: solid 7px transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
  }

  &__title {
    border-bottom: 1px solid #E0E0E0;
    min-height: 51px;
    color: #474849;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 12px  15px;
    cursor: pointer;
    user-select: none;
    img {
      width: 23px;
      height: 20px;
      object-fit: cover;
    }
    .icon {
      font-size: 21px;
      width: 23px;
      display: flex;
      justify-content: center;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: #eeeeee;
    }
    &.request {
      cursor: default;
    }
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 6px;
  }
}
