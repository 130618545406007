.details {
    header {
        padding-bottom: 14px;

        .header-mock {
            padding: 0 34px;
            height: 74px;
            display: flex;

            .button-block {
                display: flex;
                margin-left: auto;
                align-items: center;

                .left-button {
                    margin-right: 20px;
                }

                .duplicate-therapist-wrapper {
                    border: 1px solid #e0e0e0;
                    padding: 8px 11px;
                    margin-right: 16px;
                    cursor: pointer;

                    .duplicate-therapist-label {
                        display: flex;
                        align-items: center;

                        .duplicate-icon {
                            font-size: 20px;
                            margin-right: 10px;

                            &::before {
                                color: #f5a623;
                            }
                        }

                        span {
                            color: #f5a623;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 19px;
                        }
                    }

                    .start-process-btn {
                        &.disabled {
                            background: #e0e0e0;
                        }
                    }

                    .duplicate-therapists-wrapper {
                        position: fixed;
                        top: 60px;
                        right: 176px;
                        z-index: 1;
                    }
                }

                .duplicate-inprogress {
                    border: 1px solid #e0e0e0;
                    padding: 8px 11px;
                    color: #f5a623;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                }
            }
        }
    }

    .navigation-buttons {
        border-top: 1px solid #e0e0e0;
        background-color: #ffffff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .navigation-buttons-wrapper {
            padding: 23px;
            display: flex;
            justify-content: space-between;
        }
    }

    .field-data-wrapper {
        width: 100%;
        display: flex;
        padding-right: 35px;

        .field-data {
            width: 80%;
        }

        .actions {
            width: 20%;
            display: flex;

            button {
                margin-left: auto;
            }
        }
    }

    .field-group-wrapper {
        display: flex;

        .field-group {
            padding-right: 35px;
            color: #474849;
            width: 50%;

            .view-field {
                min-height: 50px;
                padding: 8px 0 5px 8px;

                .base-input-label {
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 13px;
                }

                .view-value {
                    letter-spacing: 0;
                    line-height: 22px;
                }

                &.display-internal-note {
                    .view-value {
                        word-wrap: break-word;
                    }
                }
            }
            div {
                word-break: break-all;
            }
        }
    }

    &.old-details {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1;

        header {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
        }

        .old-details-content {
            flex: 1 0 auto;
            height: 400px;
            display: flex;
            flex-direction: column;

            .tabs {
                &.horizontal {
                    .tabs-active-content {
                        .tab-with-table {
                            &.tab-with-table-and-tabs {
                                &.communication {
                                    &.notes-tab,
                                    &.emails-tab,
                                    &.interviews-tab {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        flex: 1 1 auto;

                                        .left-side-tabs-with-table {
                                            height: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            flex: 1 1 auto;

                                            .tabs {
                                                &.vertical {
                                                    .tabs-nav {
                                                        &.nav {
                                                            &.navbar-nav {
                                                                &.navbar-left {
                                                                    top: 0;
                                                                    padding-top: 24px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .tabs-active-content {
                                                        .table-page {
                                                            min-height: 100%;
                                                            height: 100%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
