.display-value__wrapper {
  display: flex;
  margin-bottom: 16px;
  .display-value__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-right: 10px;
  }
  .base-input-label {
    padding-top: 2px;
    color: #474849;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
  }
.view-field{
  display: flex;
  flex-direction: column;
}
  .view-value {
    color: #474849;
    font-family: 'Inter', sans-serif;
    font-width: 400;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}
