.details {
  .new-table-header-top-bar, {
    padding: 16px 13px 16px 25px!important;
    border-bottom: 1px solid #e0e0e0;
  }

  .vertical .tabs-active-content .new-table-header-top-bar {
      padding: 0 13px 16px 0!important;
      border-bottom: 1px solid #e0e0e0;
  }
}
