.filter-set {
  &__header {
      height: 50px;
      border-bottom: 1px solid #E0E0E0;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: 600;
      }
  }

  &__btn {
    border: none;
    opacity: 0.5;
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
  }

  &__btn--blue {
    color: #0192C9;
    opacity: 1;
  }

  &__spacer {
    margin-left: auto;
    margin-right: auto;
  }

  .ml-5 {
    margin-left: 5px;
  }
}

.filter-set--fix-width {
  .custom-column-filter-input {
    width: 270px!important;
  }
}

.filter-set--align-right {
  .custom-column-filter-input {
    left: unset;
    right:  5px;
    position: absolute!important;
  }
  .column-filter-options-wrapper {
    left: unset;
    right: 5px;
  }
}

.filter-set--align-left {
  .custom-column-filter-input {
    left:  7px;
    top: -1px;
    right: unset;
    position: absolute!important;
  }
  .column-filter-options-wrapper {
    left: 7px;
    right: unset;
  }
}

#popups {
  .ag-custom-filter-popup.ag-menu {
    overflow-y: initial!important;
  }

  .ag-theme-alpine .ag-custom-filter-popup.ag-popup-child:not(.ag-tooltip-custom) {
    box-shadow: none;
  }

  .ag-theme-alpine .ag-menu.ag-custom-filter-popup{
    border: none;
    border-color: transparent;
    background: transparent!important;
    box-shadow: none;
  }

  .ag-custom-filter-popup .custom-column-filter-input {
    position: static !important;
  }

  .ag-custom-filter-popup .column-filter-options-wrapper {
    position: static;
    margin-top: 10px;
  }

  .no-scroll {
    overflow: hidden;
  }
}


.ag-theme-alpine .ag-popup {
  .ag-set-filter-popup {
    .ag-input-field {
      margin: 0;
    }

    input.ag-text-field-input {
      width: 100%;
      padding: 7px 15px 0 15px;
      color: #474849;
      font-size: 16px;
      outline: none;
      box-shadow: none;
      position: relative;
      border: 1px solid #e0e0e0;
      height: 50px;
      border-radius: 0;
      display: flex;

      &:focus, &.focus-input {
        outline: none;
        border-left: 2px solid #0192C9!important;
        padding-left: 14px;
      }
      + label.placeholder {
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        color: #474849;
        transition: all .3s ease;
        z-index: 1;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:focus + label.placeholder, &.focus-input + label.placeholder{
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        top: 13px;
        line-height: 13px;
        transition: all .3s ease;
      }
    }

    // body
    .ag-set-filter-list,
    .ag-filter-no-matches {
      margin-top: 10px;
      padding-top: 50px;
      width: 270px;
      height: 345px;
      box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
      background-color: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 0px;
    }

    .ag-filter-virtual-list-item:hover {
      background-color: #E5F4F9;
      cursor: pointer;
    }

    .ag-set-filter-item-checkbox {
      height: 100%;
      width: 100%;
    }

    .ag-input-field-label {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 100%;
      font-family: 'Inter', sans-serif!important;
      font-weight: normal!important;
      vertical-align: middle!important;
      box-sizing: border-box!important;
      margin-left: 14px;
    }

    .ag-filter-apply-panel {
      height: 50px;
      top: -345px;
      position: relative;
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #E0E0E0;
      padding: 0 10px;
      display: flex;
      align-items: center;
      width: 267px;
      margin-left: 2px;
    }

    button[ref="clearFilterButton"] {
      margin-right: auto;
      margin-left: 0;
      border: none;
      width: 101px;
      color: transparent;


      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      background-color: #fff;
      cursor: pointer;
      outline: none!important;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        background: #fff!important;
      }
      &:before {
        content: "Clear filters";
        color: #0192C9;
        position: absolute;
        top: 15px;
        left: 10px;
      }
    }

    button[ref="cancelFilterButton"] {
      margin-right: 5px;
      margin-left: auto;
      border: none;
      padding-left: 0;
      padding-right: 0;
      width: 55px;
      color: #474849;
      opacity: 0.5;


      font-size: 16px!important;
      font-weight: 500!important;
      letter-spacing: 0!important;
      line-height: 19px!important;
      background-color: #fff!important;
      cursor: pointer!important;
      outline: none!important;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        background: #fff!important;
      }
    }

    button[ref="applyFilterButton"] {
      margin-right: 0;
      margin-left: 0;
      border: none;
      padding-left: 0;
      padding-right: 0;
      width: 55px;
      color: transparent;

      font-size: 16px!important;
      font-weight: 500!important;
      letter-spacing: 0!important;
      line-height: 19px!important;
      background-color: #fff!important;
      cursor: pointer!important;
      outline: none!important;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        background: #fff!important;
      }
      &:before {
        content: "Save";
        color: #0192C9;
        position: absolute;
        right: 17px;

      }
    }
  }
}

