.custom-small-dropdown-wrapper {
    position: relative;
    color: #474849;


    #sessionsDropdownWrapper{
        font-size: 15px;
        width: 150px;
    }

    @media (max-width: 724px) {
        #sessionsDropdownWrapper{
            width: 150px;
        }
    }
}


.custom-small-dropdown-options-list {
    width: 300px;
    padding: 10px 0;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
    position: absolute;
    right: 0;
    top: 105%;
    display: flex;
    flex-direction: column;
    z-index: 11;

    .option-item {
        padding: 10px 16px;
        cursor: pointer;
        z-index: 2;

        &:hover {
            background-color: #E5F4F9;
        }
    }

    .option-item:nth-child(4) {
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        color: #474849;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
    }
}
.custom-small-dropdown-options-list.popper-container {
    position: static;
}
