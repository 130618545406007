.status-dropdown-wrapper {
    margin-right: 10px;

    .select-value {
        font-weight: 500;
    }

    &.new {

        .select-value {
            color: #525253;
        }
    }

    &.scheduleinterview {

        .select-value {
            color: #f5b03b;
        }
    }

    &.interviewscheduled {

        .select-value {
            color: #c52fe4;
        }
    }

    &.onboarding {

        .select-value {
            color: #3294ff;
        }
    }

    &.active {
        .select-value {
            color: #009068;
        }
    }

    &.inactive {
        .select-value {
            color: #FF595F;
            font-weight: 500;
        }
    }

    &.pause {
        .select-value {
            color: #f5b03b;
        }
    }

    .custom-select-wrapper {
        height: 34px;
        max-height: 34px;
        min-height: 34px;
        box-sizing: border-box;
        width: max-content;
        min-width: 172px;

        &::after {
            right: 5px;
        }

        .option-list {
            width: 300px;
        }

        .custom-select-display {
            padding: 7.5px 10.5px;
            height: 34px;
            max-height: 34px;
            min-height: 34px;
            overflow: hidden;

            >div {
                display: flex;
            }

            .custom-select-description {
                display: none;
            }

            .select-value {
                padding-right: 22px;
            }
        }

        &.open {

            .option-list {

                .option-item {

                    &.active {
                        pointer-events: none;
                        color: #009068;
                        cursor: default;

                        &:hover {
                            background: none;
                        }
                    }
                }
            }
        }
    }
}
