.divider {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 30px 0 30px 0;
  padding: 0;
  display: block;
  max-width: 382px;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0 0;
  transition: inherit;
  color: #E0E0E0;
}

.patient-request-details {
  max-width: 382px;
  flex-grow: 1;

  .card__body {
    padding: 0;
  }
  .card__text {
    padding-bottom: 5px;
  }
}
