.zoomer {
  position: fixed;
  bottom: 24px;
  right: 34px;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: #000000;
  border-radius: 7px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0,0,0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;

  &__btn {
    flex: 3;
    border-radius: 7px;
    display: flex;
    padding: 0;
    border: 0;
    background: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  &__count {
    margin: 0 8px;
    width: 32px;
    text-align: center;
    flex: 4;
  }
}
