.table-scroll-wrapper {
    position: relative;
    width: 30px;
    border-left: 1px solid #E0E0E0;
    background-color: #fff;

    .table-scroll-extend-button {
        height: 75px;
    }

    .table-scroll-outer {
        height: calc(100% - 103px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 28px;
        }
    
        &::-webkit-scrollbar-track {
            box-shadow: inset 0px 0px 10px 10px transparent;
            border: solid 10px transparent;
        }
    
        &::-webkit-scrollbar-thumb:vertical{
            box-shadow: inset 0 0 10px 10px #D8D8D8;
            border-top: solid 2px transparent;
            border-left: solid 2px transparent;
            border-bottom: solid 2px transparent;
            border-right: solid 11px transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
        }
    }

    &.with-shadow {
        box-shadow: -13px 0px 20px -10px rgba(0, 0, 0, 0.5);
    }
}