.card-days-wrapper {
    .days-wrapper-header {
        display: flex;
        justify-content: space-between;
        color: #474849;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        margin-bottom: 10px;
    }

    .days-wrapper-header-title {
        display: flex;
        align-items: center;
    }

    .days-items-wrapper {
        .day-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .time-ranges-wrapper {
                display: flex;
                width: 180px;
                justify-content: space-between;

                .base-input-wrapper {
                    width: 84px;
                    height: 25px;
                }

                .custom-field-outer {
                    height: 25px;
                }

                .time-label {
                    display: flex;
                    background-color: #F5F5F5;
                    border-radius: 3px;
                    padding: 5px;
                    width: 84px;
                    height: 24px;
                    color: #474849;
                    font-size: 13px;
                    line-height: 16px;
                    justify-content: center;
                }
            }
            label {
                margin-right: 8px;
            }
            .day-label {
                color: #474849;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
}
.request-special-wrapper {
    width: 100%;
}


.nh-request-grid-wrapper {
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    width: 100%;
    max-width: 1550px;
    row-gap: 10px;
    gap: 10px;
    > div {
        margin: 0;
    }

    .box-1 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: 1;
        min-width: 300px!important;
    }

    .box-2 {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
    }



    .box-4 {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 5;
        min-width: 690px;
    }
}

.nh-request-grid-wrapper.lg-card {
    grid-template-columns:  repeat(3, 1fr);
    max-width: 1164px;
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 60;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    .box-2 {
        grid-column-start: 2;
        grid-column-end: 4;
        max-width: 100%;
    }
    .box-4 {
        grid-row-start: 2;
        grid-row-end: 30;
        grid-column-start: 2;
        grid-column-end: 4;
        min-height: 100%;
        min-width: 752px;
        max-width: 864px;
    }
}
.nh-request-grid-wrapper.md-card {
    grid-template-columns:  repeat(3, 1fr);
    max-width: 1160px;
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 60;
    }
    .box-2 {
        grid-column-start: 2;
        grid-column-end: 4;
        max-width: 100%;
    }
    .box-4 {
        grid-row-start: 2;
        grid-row-end: 30;
        grid-column-start: 2;
        grid-column-end: 4;
        min-height: 100%;
        min-width: 650px;
        width: 100%;
    }
}
.nh-request-grid-wrapper.sm-card {
    grid-template-columns:  repeat(2, 1fr);
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 30;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-2 {
        grid-row-start: 1;
        grid-row-end: 30;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-4 {
        grid-row-start: 31;
        grid-row-end: 36;
        grid-column-start: 1;
        grid-column-end: 4;
        min-width: 100%;
        margin-top: 0;
        min-height: 230px;
    }
}
.nh-request-grid-wrapper.extra-sm-card {
    grid-template-columns:  repeat(1, 1fr);
    .box-1 {
        grid-row-end: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-2 {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-4 {
        grid-row-start: 3;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
        min-width: 100%;
        margin-top: 0;
        min-height: 230px;
    }
}
.general-tab {
    .tabs-nav {
        max-width: 170px!important;
    }
    .tabs-active-content {
        padding-left: 24px!important;
        padding-right: 12px;
    }
}
