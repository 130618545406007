.inactive-wrapper {

    .right-side-bar {
        
        .right-side-bar-body {

            .field-set {
                width: 350px;
            }
        }
    }
}