.department-filter{
  margin-right: 16px;
  min-width: 147px;
  .custom-select-display {
    >div {
      max-height: 18px;
      overflow: hidden;
    }
  }
}
