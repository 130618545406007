.message-type-filter {

    .button-item {
        color: #474849;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        padding: 8.5px 10.5px;
        border: 1px solid #E0E0E0;   
        background-color: #ffffff;
        cursor: pointer;

        &.active {
            color: #0192C9;
            border: 0.5px solid #0192C9;
            background-color: #E5F4F9;
        }
    }

}