.tooltip-btn {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #E0E0E0;

  &__icon {
    font-size: 24px;
    margin-left: 6px;
  }
}

.tooltip-btn--disabled {
  order: -1;
  color: #9E9E9E!important;
  background: transparent!important;
  border: 0;
}

.tooltip-text {
  letter-spacing: 0;
  text-transform: uppercase;
}

.tooltip-description {
  .react-tooltip-lite {
    padding: 9px 6.5px!important;
    margin-left: 3px;
  }

  .react-tooltip-lite-arrow {
    margin-left: 3px;
  }
}

.tooltip-content-status {
  display: flex;
  padding-left: 5px;
}
