input[type='checkbox'].black-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

input[type='checkbox'].black-checkbox + span {
    cursor: pointer;
    font: 16px sans-serif;
    color: black;
}

input[type='checkbox'].black-checkbox + span:before {
    content: '';
    border: 1px solid grey;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: -2px;
}

input[type='checkbox'].black-checkbox:checked + span:before {
    background-image: url('../../../images/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    border-radius: 0px;
    color: white;
}

input[type='checkbox'].black-checkbox:focus + span:before,
input[type='checkbox'].black-checkbox:not(:disabled) + span:hover:before {
    box-shadow: none;
    outline-color: transparent;
}

input[type='checkbox'].black-checkbox:disabled + span {
    cursor: default;
    color: black;
    opacity: 0.5;
}

.map-legend {
    font-family: Arial, sans-serif;
    padding: 10px;
    margin: 10px;
    min-width: 150px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 2px 2px 10px 2px rgba(31, 37, 50, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__group-title {
        padding-bottom: 15px;
        font-size: 14px;
    }

    &__item {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
            width: 23px;
            height: 20px;
            object-fit: cover;
        }
        .icon {
            font-size: 21px;
            width: 23px;
            display: flex;
            justify-content: center;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__label {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        margin-left: 6px;
    }
    .home {
        color: #af52de;
    }
    .preference {
        color: #14a501;
    }
    .request {
        color: #5856d6;
    }
}
.map-legend__item--grouped {
    margin-left: 20px;
}
