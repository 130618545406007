.therapist-background-check {
    display: flex;
    flex-direction: column;

    .table-header-top-bar {

        .table-header-actions {

            .base-text-btn {
                margin: 0;
            }
        }
    }

    .ag-row-level-1 {
        background-color: #e6e6e6 !important;
    }

    .right-side-bar {

        .right-side-bar-body {

            .edit-therapist-form {

                .status-field {
                    width: fit-content;

                    .custom-field-outer {

                        .custom-select-wrapper {
                            height: 35px;
                            min-height: 35px;

                            .custom-select-display {
                                min-width: 150px;
                                height: 35px;
                                min-height: 35px;
                                padding: 8px 40px 8px 11px;
                            }

                            &.open {

                                &::before {
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-info-wrapper {
        flex: 1;

        .ag-table-main {

            .invalid {
                color: #F5A623;
            }

            .valid {
                color: #43910A;
            }

            .expired {
                color: #FF595F;
            }

            .waiting {
                color: #474849;
            }
        }
    }
}

.custom-select-wrapper {

    .custom-select-display {

        .select-value {

            &.passed {
                color: #009068;
            }
        }
    }

    .option-list {

        .option-item {

            &.passed {
                color: #009068;
            }
        }
    }
}