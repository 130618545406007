.enrollment-history {
  border: 1px solid #000000;
  padding: 20px;
  margin-top: 20px;
  &__title {
    padding-bottom: 25px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
  &__table {
    min-width: 600px;
    display: flex;
    flex-direction: column;
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden
  }
  &__header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 5px;

    .enrollment-history__column{
      color: #000000;
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  &__row {
    display: flex;
    width: 100%;
    padding-bottom: 5px;
  }
  &__column {
    flex: 1;
    display: inline-flex;
    flex-wrap: wrap;
    white-space:break-spaces;
    word-break: break-word;
    padding-right: 10px;
  }
  .eh-patient {
    max-width: 140px;
  }
  .eh-start-date {
    max-width: 120px;
  }
  .eh-end-date {
    max-width: 160px;
  }
}

.enrollment-history-list {
  .ag-table-main {
    margin-top: 0;
  }
}
