.infinite-input-field{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  .base-input-wrapper {
    width: 100%;
  }
  .view-password {
    font-size: 24px;
    margin-left: -36px;
    z-index: 2;
  }
}
