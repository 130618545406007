.therapists-window {
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        height: 28px;
        width: 18px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        border: solid 10px transparent;

    }

    &::-webkit-scrollbar-thumb:vertical {
        box-shadow: inset 0 0 10px 10px #D8D8D8;
        border-top: solid 5px transparent;
        border-left: solid 7px transparent;
        border-bottom: solid 10px transparent;
        border-right: solid 7px transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
    }
}


.info-wrapper {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;

    &::-webkit-scrollbar {
        height: 28px;
        width: 18px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        border: solid 10px transparent;
    }

    &::-webkit-scrollbar-thumb:vertical {
        box-shadow: inset 0 0 10px 10px #D8D8D8;
        border-top: solid 5px transparent;
        border-left: solid 7px transparent;
        border-bottom: solid 10px transparent;
        border-right: solid 7px transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
    }
}
