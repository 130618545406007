.similar-existing-entity-dialog {
    width: 340px;
    border: 1px solid #e0e0e0;
    padding: 23.5px;
    color: #474849;
    background-color: #fff;
    max-height: 300px;

    .similar-existing-entity-dialog-title {
        font-weight: 500;
        display: flex;
        text-align: center;
        margin-bottom: 23px;
    }

    .similar-existing-entity-dialog-body {
        margin-bottom: 23px;
        max-height: 120px;
        overflow-y: auto;

        .similar-existing-entity-wrapper {
            display: flex;
            min-height: 40px;

            .similar-existing-entity {
                display: flex;
                flex-direction: column;
                padding: 4px 14px;
                width: calc(100% - 40px);
                border: 1px solid #fff;
                background-color: #f8f8f8;

                :nth-child(2) {
                    font-size: 10px;
                    font-weight: 600;
                    opacity: .8;
                }
                .similar-existing-entity-full-name {
                    white-space: nowrap;
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .similar-existing-entity-link {
                width: 40px;
                border: 1px solid #fff;
                background-color: #f8f8f8;
                position: relative;
                cursor: pointer;

                &::after{
                    content: $icon-open-in;
                    font-family: 'icomoon';
                    position: absolute;
                    font-size: 25px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #474849;
                }
            }
        }
    }

    .similar-existing-entity-dialog-actions {
        display: flex;
        justify-content: flex-end;

        :first-child {
            margin-right: 10px;
        }
    }
}

.similar-existing-entity-dialog-wrapper {
    position: fixed;
    top: 60px;
    right: 23px;
}
