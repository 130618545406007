@mixin font-style($c, $f-s, $f-v, $l-h) {
    color: $c;
    font-size: $f-s;
    font-weight: $f-v;
    line-height: $l-h;
}

.placement-info {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;

    .placement-info-data {
        .placement-therapist-name {
            @include font-style(#474849, 16px, 500, 22px);
            width: 200px;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .placement-address {
            @include font-style(#474849, 12px, 400, 15px);
            padding-bottom: 10px;
        }

        .placement-department,
        .placement-name {
            @include font-style(#474849, 12px, 400, 15px);
        }

        .request-status {
            @include font-style(#474849, 16px, 400, 19px);
            padding: 14px 0 20px;

            &.new {
                color: #525253;
            }

            &.scheduleinterview {
                color: #f5b03b;
            }

            &.interviewscheduled {
                color: #c52fe4;
            }

            &.onboarding {
                color: #3294ff;
            }

            &.active {
                color: #009068;
            }

            &.inactive {
                color: #ff595f;
            }

            &.hold {
                color: #474849;
            }
        }
    }
}

.placement-info-actions {
    padding: 8px 10px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .open-profile {
        margin-left: auto;
    }
}
