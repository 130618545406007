.infinity-app {
    overflow: hidden;
    display: flex;

    section {
        height:100%;
        position: relative;

        >div {
            height:100%;
        }
    }
}

.logo {
    box-sizing: border-box;
    height: 51.61px;
    width: 51px;
    border: 1px solid #E0E0E0;
}

.login-form {
    width: 424px;
    border: 1px solid #E0E0E0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35.5px 40px;
    display: flex;
    flex-direction: column;

    .infinite-logo {
        height: 50px;
        width: 50px;
        align-self: center;
        background-image: url('../../../common/images/login-form-logo.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px;
        margin-bottom: 25px;
    }

    h4 {
        text-align: center;
        font-size: 1.125rem;
        font-weight: 600;
        color: #474849;
        line-height: 22px;
        margin-bottom: 30px;
    }

    h6 {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #474849;
        line-height: 18px;
        letter-spacing: 0;
        margin-top: -18px;
        margin-bottom: 30px;
        display: block;
        span.bold {
            font-weight: 800;
        }
    }

    .login-error {
        font-weight: 500;
        text-transform: uppercase;
        color: #FF595F;
        font-size: 15px;
        text-align: center;
        margin-bottom: 10px;
    }

    .login-btn {
        height: 50px;
        width: 100%;
        justify-content: center;
    }

    .login-input-wrapper {
        margin-bottom: 14px;
    }
}

.login-form {
    input:-webkit-autofill {
        + span {
            top: 13px;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
        }
    }
}

@media (max-width: 712px) {
   .login-form {
       width: 100vw;
       border: none;
   }
}

.form-label {
    height: 16px;
    background-color: #ECECEC;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding-right: 3px;
    cursor: pointer;
    margin-top: -4px;
    .icon {
        font-size: 12px;
        margin-right: 2.5px;
    }
    &__content {
        color: #474849;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 10px;
        padding-top: 2px;
    }
}

.logo-external {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    font-weight: 500;

    &__customer {
        font-weight: 700;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: break-spaces;
    }
    &__text {
        font-weight: 700;
        padding-right: 5px;
    }
    &__user {
        min-height: 34px;
        color: #474849;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 10.5px;
    }
}
