.navigation-bar {
    &.expanded {
        .left-sidebar-menu {
            width: 260px;
            height: 100%;

            .side-bar {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
                height: 100%;

                .sidebar-logo {
                    flex: unset;
                    min-height: 200px;
                    transition: 0.3s linear min-height;
                    justify-content: center;
                    align-items: center;
                    .infinite-logo {
                        position: static;
                        display: block;
                        min-width: 80px;
                        min-height: 80px;
                        background-size: 80px;
                        top: 50%;
                        left: 0;
                        transform: unset;
                        transition: 0.3s linear width, 0.3s linear height, 0.3s linear top, 0.3s linear background-size;
                        margin: 13px auto 12px auto;
                    }

                    .logo-text {
                        opacity: 1;
                        transition: 1s linear opacity;
                        position: static;
                        top: unset;
                    }
                }
            }
        }

        .show-hide-extend-left-sidebar {
            div {
                &:after {
                    transform: translateY(-50%) rotate(45deg);
                    transition: 0.3s linear transform;
                }
            }
        }

        .sidebar-navigation-group-item {
            .group-name {
                .navigation-main-item-wrapper {
                    display: flex;
                    align-items: center;
                    padding: 0px 20px;
                    cursor: pointer;
                    height: 100%;
                    position: relative;

                    .navigation-main-item-text {
                        font-weight: 400;
                        white-space: nowrap;
                        font-size: 16px;
                    }

                    .icon-down {
                        font-size: 24px;
                        position: relative;
                        top: 7px;
                        margin-left: auto;
                        color: #9E9E9E;
                        transform: rotate(180deg);

                        &.closed {
                            transform: rotate(0deg);
                            top: -2px;
                        }
                    }

                    .navigation-main-item {
                        width: initial;
                        position: initial;
                        margin-right: 12px;
                    }
                }
            }

            .navigation-group-items {
                margin-left: 56px;

                .navigation-item {
                    font-size: 14px;
                    padding: 10px 10px 10px 0;
                    display: block;
                    white-space: nowrap;

                    &.active-navigation-sub-item {
                        outline: none;

                        span {
                            color: #0192C9;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    .left-sidebar-menu {
        height: 100%;
        .navigation-item {
            font-size: 14px;
            white-space: nowrap;

            &.active-navigation-sub-item {
                outline: none;
                i {
                    color: #0192C9;
                }
                span {
                    color: #0192C9;
                    font-weight: 500;
                }
            }
        }
        .side-bar {
            .sidebar-logo {
            position: relative;
            min-height: 65px;
            transition: 0.3s linear min-height;
            padding: 7px;
            border-bottom: 1px solid #E0E0E0;
            pointer-events: none;

                .infinite-logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50px;
                    height: 50px;
                    transition: 0.3s linear width, 0.3s linear height, 0.3s linear top, 0.3s linear background-size;
                    background-image: url('../../common/images/login-form-logo.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50px;
                }

                .logo-text {
                    position: absolute;
                    top: 60%;
                    color: #474849;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    opacity: 0;
                    width: calc(100% - 14px);
                }
            }

            .sidebar-nav {
                margin: 5px 0;
            }
        }
    }
}

.content {
    .left-shadow {
        transition: 0.3s linear left
    }

    &.compressed {
        width: calc(100% - 260px);

        .left-shadow {
            left: -41px;
            transition: 0.3s linear left
        }
    }
}

.sidebar-navigation-group-item {

    .group-name {
        height: 60px;

        .navigation-main-item {
            display: flex;
            width: 100%;
            height: 100%;
            font-size: 24px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.active {
                color: #0192C9;

                &::after {
                    content: "";
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 3px;
                    background-color: #0192C9;
                    position: absolute;
                }
            }
        }
    }
}

.notification-bell {
    background-image: url('../../common/images/notification-bell.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    width: 34px;
    height: 34px;
    pointer-events: all;
    margin: 15px auto 5px auto;
    cursor: pointer;

    &.active,
    &:hover {
        background-color: #E0E0E0;
    }
}

.notification-center {
    background-color: #fff;
    border: 1px solid #D7D7D7;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    width: 400px;
    position: absolute;
    z-index: 1;
    left: 100px;
    pointer-events: all;

    .header {
        border-bottom: 1px solid #E0E0E0;
        height: 50px;
        padding: 0 15px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .body {
            color: #474849;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;

            .new-count {
                color: #0192C9;
                padding-left: 10px;
            }
        }

        .close-notification-center-btn {
            background-color: #E6E4E4;
            color: #909595;
            border-radius: 100px;
            font-size: 15px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
            float: right;
        }
    }

    .notifications-list {
        max-height: 60vh;
        overflow-y: auto;
    }

    .notification-item {
        height: 90px;
        width: 100%;
        padding: 12px 14px;
        text-align: left;
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .created-by {
            color: #474849;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 13px;
        }

        .text {
            color: #474849;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
        }

        .show-more {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 14px;
        }

        .date {
            height: 16px;
            width: 90px;
            background-color: #474849;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;

            &.today {
                background-color: #0192C9;
                width: 98px;
            }
        }
    }
}