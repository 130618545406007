.chip{
    display: flex;
    border: 1px solid #E0E0E0;
    height: 34px;
    justify-content: center;
    align-items: center;
    .text{
        font-size: 16px;
        font-weight: 500;
        color: #474849;
        padding-right: 10.5px;
        // line-height: 34px;
        // vertical-align: middle;
    }
    .icon{
        font-size: 24px;
        padding-left: 10.5px;
        padding-right: 10px;
        // vertical-align: middle;
        // line-height: 34px;
    }
}