@mixin font-style ($color, $font-size, $font-weight, $line-height) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

.clear-filter {
    display: none;
}


.communication {

    .ag-table-main {
        margin-top: 36px;
    }

    .ag-header {
        display: none;
    }

    .table-page {

        .ag-center-cols-clipper {

            .ag-center-cols-viewport {

                .ag-center-cols-container {
                    padding-left: 0 !important;

                    .sender-info {
                        display: flex;

                        .avatar-wrapper {

                            .avatar-initials {
                                text-align: center;
                                @include font-style (#474849,  12px, 800, 15px);
                            }
                        }
                        .email-table-sender {
                            @include font-style (#474849, 14px, 500, 18px);
                            display: flex;
                            align-items: center;
                        }
                    }

                    .email-table-message-cell {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;

                        .email-table-message-title {
                            @include font-style (#474849, 14px, 500, 18px);
                        }

                        .email-table-message-text {
                            @include font-style (rgba(71,72,73,0.7), 14px, 500, 18px);
                            width: 100%;
                            text-overflow: ellipsis;
                            width: 100%;
                            display: block;
                            overflow: hidden;
                            height: 18px;

                            div {
                                width: 100%;

                                div {
                                    &:first-child {
                                        text-overflow: ellipsis;
                                        width: 100%;
                                        display: block;
                                        overflow: hidden;
                                    }
                                    &:not(:first-child) {
                                        display: none;
                                    }
                                    br {
                                        height: 0;
                                    }
                                }
                            }
                        }
                    }
                    .ag-cell {
                        display: flex;
                        align-items: center;
                    }

                    .created-at {
                        opacity: 0.8;
                        @include font-style (#474849, 10px, 800, 13px);
                        text-transform: uppercase;
                    }

                    .icon-email,
                    .icon-notes {
                        font-family: 'icomoon';
                        font-size: 20px;
                    }
                    .icon-email {
                        color: #007AFF;
                    }
                    .icon-notes {
                        color: #34C759;
                    }
                }

            }
        }
    }

    &.notes-tab {
        .ag-row {
            background: none;
            border: none;
        }
    }

    &.notes-tab,
    &.interviews-tab,
    &.emails-tab {
        .vertical .tabs-active-content .new-table-header-top-bar {
            padding: 24px 13px 16px 0 !important;
        }
        .base-input-wrapper-with-ico {
            width: 370px;
            input {
                padding: 17px 0 0 48px;
            }
        }
        @media screen and (max-width: 1400px) {
            .table-header-actions {
                margin: 0;
            }
        }
        @media screen and (max-width: 1400px) {
            .table-header-actions {
                margin: 0;
            }
            .table-header-actions button.table-header-add-button {
                margin-left: 16px;
            }
        }
    }

    &.notes-tab{
        @media screen and (max-width: 892px) {
            .table-header-actions {
                margin-top: 16px;
            }
            .table-header-actions button.table-header-add-button {
                margin-left: 0;
                margin-right: 16px;
            }
        }
    }

    &.interviews-tab {
        @media screen and (max-width: 1094px) {
            .table-header-actions {
                margin-top: 16px;
            }
            .table-header-actions button {
                margin-left: 0;
                margin-right: 16px;
            }
        }
    }

    &.emails-tab {
        @media screen and (max-width: 1224px) {
            .table-header-actions {
                margin-top: 16px;
            }
            .table-header-actions button {
                margin-left: 0!important;
                margin-right: 16px;
            }
        }
    }

    .clear-filter {
        display: flex;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        font-family: 'icomoon';
        font-size: 20px;
    }

    .title-counter {
        display: flex;
        align-items: center;

        .count-indicator {
            margin-left: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: 10px;
            background-color: #0192C9;
            color: #fff;
        }
    }
    .mr-15 {
        margin-right: 15px;
    }
}
