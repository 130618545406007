.assigned-therapist-table {
    min-width: 762px;
    max-width: 762px;
    flex-grow: 1;

    @media screen and (max-width: 1159px) {
        min-width: 90%;
    }

    min-height: 230px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .ag-header .table-dropdown-filter-main-view,
    .ag-row {
        cursor: default;
    }

    .ag-table-main {
        height: unset!important;
    }
    .card__body {
        flex: 1;
        padding: 0;

        .status {
            margin-right: 10px;
            color: #ffffff;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
            background-color: grey;
            width: fit-content;
            text-transform: uppercase;
            line-height: 10px;
            padding: 3px 4px;
            &.assigned {
                background-color: #34C759;
            }
            &.added {
                background-color: #f5a623;
            }
            &.offered {
                background-color: #007aff;
            }
            &.pendinginterview {
                background-color: rgb(189, 16, 224);
            }
        }

        .custom-small-dropdown-button {
            border: none;
        }
        .default-small-btn-with-icon.active-btn,
        .default-small-btn-with-icon:hover {
            border: none;
            background-color: transparent;
        }
    }

    .card__header {
        position: relative;

        .card__title {
            .icon-search {
                position: absolute;
                right: 14px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .total-row-bar {
        transform: translateX(10px)
    }
    .open-log-cell {
        font-size: 24px;
    }
}



.assigned-therapist {
    height: 100%;
    min-height: 100%;
    .card__body {
        .assigned-list-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                height: 28px;
                width: 18px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 10px 10px transparent;
                border: solid 0 transparent;
            }

            &::-webkit-scrollbar-thumb:vertical {
                box-shadow: inset 0 0 10px 10px #d8d8d8;
                border-top: solid 10px transparent;
                border-left: solid 7px transparent;
                border-bottom: solid 10px transparent;
                border-right: solid 7px transparent;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
            }

            .list-item-wrapper {
                display: flex;
                flex-direction: column;

                .first-line {
                    display: flex;
                    flex-direction: row;
                    margin: 8px 0;

                    .avatar-wrapper {
                        display: flex;
                        margin-right: 10px;

                        img {
                            width: 32px;
                            height: 32px;
                            object-fit: cover;
                        }

                        &.assigned-therapist-avatar {
                            width: 34px;
                            height: 34px;

                            .avatar-initials {
                                font-size: 14px;
                                width: 34px;
                                height: 34px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    .info-block {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        .info-block-bio {
                            .selected-therapist-label {
                                font-size: 10px;
                                font-weight: 600;
                                letter-spacing: 0;
                                line-height: 13px;
                                text-transform: uppercase;
                            }
                            .fullname {
                                color: #474849;
                                font-size: 16px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 22px;
                            }
                            .disciplines {
                                display: inline-block;
                                color: #474849;
                                font-size: 10px;
                                font-weight: 600;
                                letter-spacing: 0;
                                line-height: 13px;
                                margin-right: 10px;
                            }
                            .rateLevel {
                                display: inline-block;
                                font-size: 10px;
                                font-weight: 600;

                                &.one {
                                    color: #34c759;
                                }
                                &.two {
                                    color: #f5a623;
                                }
                                &.three {
                                    color: #ff375f;
                                }
                            }
                        }
                        .status {
                            margin-right: 10px;
                            color: #ffffff;
                            font-size: 10px;
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: 13px;
                            text-align: center;
                            background-color: grey;
                            width: fit-content;
                            padding: 2px 4px;

                            &.added {
                                background-color: #f5a623;
                            }
                            &.offered {
                                background-color: #007aff;
                            }
                        }
                        .status-with-button {
                            display: flex;
                            align-items: center;
                            flex-direction: row;

                            .custom-small-dropdown-wrapper {
                                position: relative;

                                .icon-options {
                                    border: none;
                                }

                                .default-small-btn-with-icon {
                                    &:hover {
                                        background: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .second-line {
                    .assigned-therapist-add-note {
                        .custom-field-outer {
                            .base-input-wrapper {
                                height: 39px;
                            }
                        }
                    }

                    .separator-assigned-list {
                        height: 1px;
                        width: 100%;
                        margin: 14px 0;
                        background-color: #e0e0e0;
                    }

                    .offered {
                        opacity: 0.6;
                        color: #474849;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 18px;
                        padding: 10px 42px 10px 14px;
                        border: 1px solid #e0e0e0;
                        width: 300px;
                        margin-left: auto;

                        .offered-text {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .editable-input {
                        &.assigned-notes-wrapp {
                            padding: 10px 34px 10px 14px;
                            width: 300px;
                            margin-left: auto;
                            position: relative;

                            &.editable {
                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 100%;
                                    width: 2px;
                                    background-color: #0192c9;
                                }
                            }

                            input {
                                background: none;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: #474849;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 18px;

                                &::placeholder {
                                    opacity: 0.6;
                                    color: #474849;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }
            }
            &.no-selected {
                height: 100%;
                .no-selected-therapist {
                    opacity: 0.6;
                    color: #474849;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                }
            }
        }
    }

    .card__header {
        position: relative;

        .card__title {
            .icon-search {
                position: absolute;
                right: 14px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
