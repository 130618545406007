.details-navigation {
    padding: 5px 34px;
  
    #previousEntityBtn {
      float: left;
    }
  
    #nextEntityBtn {
      float: right;
    }
  }
  
  .details-navigation::after {
    content: "";
    clear: both;
    display: table;
  }