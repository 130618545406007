.phone {
    width: 100%;
    display: flex;

    .custom-field-outer{

        .custom-select-wrapper {

            .custom-select-display {
                padding: 20px 20px 8px 28px;

                .custom-select-description {
                    left: 28px;
                }
            }
            &::after {
                right: 3px;
            }
        }
    }

    .custom-field-outer:first-of-type {
        width: fit-content;
        flex-grow: 1;
    }

    .custom-field-outer:nth-of-type(2) {
        width: 130px;
        flex-grow: 2;
        .custom-select-wrapper {

            .custom-select-display {
                padding: 20px 20px 8px 28px;

                .custom-select-description {
                    left: 28px;
                }
            }
            &::after {
                right: 0;
            }
        }
    }

    .custom-field-outer:nth-of-type(3) {
        width: 58px;
        flex-grow: 1;
        .base-input-label{
            opacity: 0.5;
            color: #474849;
          }

        input {
            width: 100%;
            padding: 7px 8px 0;
            display: flex;


        }
    }
}
.field-list-section-single,
.field-list-section-no-draggable {
    .custom-field-outer{

        .custom-select-wrapper {

            .custom-select-display {
                padding: 20px 20px 8px 15px;

                .custom-select-description {
                    left: 15px;
                }
            }
        }
    }
}

.field-list-section {

   .main-item {

        margin-bottom: 15px;

        &::after {
            width: fit-content;
            background-color: #E0E0E0;
            color: #474849;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 13px;
            content: 'Main';
            text-transform: uppercase;
            padding: 2px;
            margin-top: 4px;
        }

        .field-list-item {

            .field-set {

                &.edit-phone {
                    margin-bottom: 0;
                }
            }
        }
        &.without-main-flag{
            &::after{
                display: none;
            }
        }
    }
    .active-item {

        margin-bottom: 15px;

        &::after {
            width: fit-content;
            background-color: #E0E0E0;
            color: #474849;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 13px;
            content: 'Active';
            text-transform: uppercase;
            padding: 2px;
            margin-top: 4px;
        }

        .field-list-item {

            .field-set {

                &.edit-phone {
                    margin-bottom: 0;
                }
            }
        }
        &.without-main-flag{
            &::after{
                display: none;
            }
        }
    }
}

.edit-phone {
    display: flex;
    flex-direction: column;
}

.field-list-section {
    >div {
        :not(:first-child) {
            .field-list-item {
                .required-field-star {
                    &::before {
                        content: "";
                    }
                }
            }
        }
    }

}

.field-list-section {

    >div {
        :first-child {
            .field-list-item {
                .required-field-star {
                    &::before {
                        position: absolute;
                        content: "*";
                        left: -15px;
                        top: 20px;
                        color: #e94a4a;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
