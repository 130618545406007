.simple-time-label {
    color: #9E9E9E;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.simple-time-picker-content {
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    flex: 1;
    height: 25px;
    &:first-child {
        margin-right: 10px;
    }
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        pointer-events: none;
        font-size: 20px;
        color: #474849;
    }

    input {
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        padding-left: 10px;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        z-index: 10;
    }

    &.with-no-value {
        input[type="time"]::-webkit-calendar-picker-indicator {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .simple-time-picker-label {
        display: flex;
        align-items: center;
        position: absolute;
        width: calc(100% - 32px);
        height: 22px;
        font-size: 13px;
        left: 10px;
        top: 1px;
        background-color: #fff;
        color: #474849;
        pointer-events: none;
    }
}
