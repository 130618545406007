.rh-info {
  padding: 0 34px;

  &__title {
    color: #474849;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  &__body {
    margin-top: 7px;
    color: rgba(71,72,73,0.7);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    span {
      font-weight: 500;
    }
  }

  &__footer {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .general-info-actions-wrapp {
      margin-bottom: 4px;
    }
    button {
      margin-left: 10px;
    }
  }

  &__dote {
    margin: 0 6px;
    display: inline-block;
    border-radius: 2px;
    width: 4px;
    height: 4px;
    background-color: #7E7E7F;
    transform: translateY(-50%);
    @media screen and (max-width: 500px){
      display: block;
      visibility: hidden;
    }
  }

  &__status {
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;

    padding: 2px 4.5px;
    display: inline-flex;
    align-items: center;
  }
}
