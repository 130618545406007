.main-status {
  display: inline-flex;
  align-items: center;
  padding: 2px 4.5px 1px 4.5px;
  color: #474849;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  text-transform: uppercase;
  background-color: #E0E0E0;
}

.phones-list-view {
  &__phone-type {
    min-width: 130px!important;
  }
}
