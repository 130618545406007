.days-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
    padding: 14px;
    padding-right: 10px;
    border: 1px solid #e0e0e0;

    .days-wrapper-header {
        display: flex;
        justify-content: space-between;
        color: #474849;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        text-transform: uppercase;
        margin-bottom: 18px;

        .icon {
            font-size: 24px;
        }
    }

    .days-wrapper-header-title {
        display: flex;
        align-items: center;
    }

    .days-items-wrapper {
        .field-set {
            margin-bottom: 12px
        }
        .flex-values-wrapper {
            margin-top: 16px
        }
        .day-item {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 10px;

            .time-ranges-wrapper {
                display: flex;
                width: 210px;
                justify-content: space-between;

                .base-input-wrapper {
                    width: 84px;
                    height: 25px;
                }

                .custom-field-outer {
                    height: 25px;
                }
            }
            label {
                margin-right: 8px;
            }
            .day-label {
                color: #474849;
                font-size: 16px;
                line-height: 19px;
            }

            &.unchecked {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    background-color: #fff;
                    opacity: 0.5;
                }
            }
            .day-checkbox-wrapper {
                cursor: pointer;
            }

            .simple-time-picker-wrapper {
                flex: 1;
                &:first-child {
                    margin-right: 6px;
                }
                input {
                    @extend .day-checkbox-wrapper;
                    padding-left: 5px;
                }
            }
        }

        .flex-values-wrapper {
            display: flex;

            .flex-times-checkbox {
                margin-left: 34px;
            }

            label {
                margin-right: 8px;
            }
            .day-label {
                color: #474849;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    &.has-error {
        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #e94a4a;
            z-index: 1;
        }
    }
}
