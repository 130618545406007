@import './src/common/styles/_mixin.scss';

.rate-level {
  height: 20px;
  width: 30px;
  border-radius: 10px;
  justify-content: center;
  @include font(#ffffff, 14px, 800, 18px);
  display: inline-flex;
  align-items: center;
  margin: 2px;

  &.one {
    background-color: #34c759;
  }
  &.two {
    background-color: #f5a623;
  }
  &.three {
    background-color: #ff375f;
  }
}
