@mixin flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.emails-tab {

    .avatar-wrapper {
        width: 35px;
        min-width: 35px;
        height: 35px;
        background-color: #e0e0e0;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        border-radius: 50%;
        @include flex-center-center;
    }

    .tabs-active-content {

        .table-header-top-bar {
            width: 100%;
            margin-left: auto;
            background: none;
            padding: 23px 34px 23px 0;

            .vertical-separator {
                display: none;
            }

            .custom-field-outer {

                .base-input-wrapper-with-ico {
                    height: 100%;

                    .base-input-label {
                        color: #474849;
                    }
                }
            }

            button {
                &::before {
                    display: none;
                }
            }
        }

        .main-info-wrapper {
            .main-table-wrapper {
                padding: 0;
                padding-top: 19.5px;
                padding-right: 33.5px;

                .role-table {
                    .table-header-wrapper {
                        display: none;
                    }

                    .table-body-wrapper {
                        .table-row-wrapper {
                            height: 67px;
                            border-bottom: 1px solid #fff;
                            background-color: #F8F8F8;

                            .table-data {
                                border: none;
                                min-width: 0;

                                &:first-of-type {
                                    display: none;
                                }

                                .table-data-inner-text {
                                    display: flex;
                                    align-items: center;
                                    left: 15.5px;
                                }

                                .email-table-message-cell {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;

                                    .email-table-message-title,
                                    .email-table-message-text {
                                        font-size: 14px;
                                        line-height: 18px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .email-table-message-text {
                                        color: rgba(71,72,73,0.7);
                                        white-space: nowrap;
                                        height: 18px;

                                        div {
                                            display: none;
                                        }
                                        div:first-of-type {
                                            display: flex;
                                        }
                                    }
                                }

                                .email-table-counter {
                                    height: 17px;
                                    width: 16px;
                                    border: 1px solid #E0E0E0;
                                    font-size: 10px;
                                    font-weight: 600;
                                    line-height: 13px;
                                    margin-right: 13.5px;
                                    @include flex-center-center;
                                }

                                .email-table-avatar {
                                    width: 35px;
                                    min-width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    background-color: #e0e0e0;
                                    margin-right: 10px;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 15px;
                                    @include flex-center-center;
                                }

                                .email-table-sender {
                                    font-size: 14px;
                                    font-weight: 500;
                                    letter-spacing: 0;
                                    line-height: 18px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .email-table-create-at {
                                    font-size: 10px;
                                    font-weight: 600;
                                    letter-spacing: 0;
                                    line-height: 13px;
                                }

                                .default-small-btn-with-icon {
                                    border: none;
                                }
                            }

                            .table-data {
                                z-index: 0;
                            }

                            .table-data:nth-of-type(2) {
                                width: 40%;
                            }
                            .table-data:nth-of-type(3) {
                                width: 45%;
                            }
                            .table-data:nth-of-type(4) {
                                width: 12%;
                            }
                            .table-data:last-of-type {
                                width: 8%;
                                text-align: center;
                                padding: 11px 14px 11px 2px;
                                z-index: 3;

                                .table-data-inner-text {
                                    overflow: initial;
                                    position: relative;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    .field-data-wrapper {

        .field-data {

            .table-header-top-bar {

                .table-header-top-bar__left-section {

                    .custom-field-outer {

                        .base-input-wrapper-with-ico {
                            min-height: 50px;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

.therapist-communications-email-new-email {

    .right-side-bar {
        color: #474849;

        .right-side-bar-body {
            .edit-therapist-form {
                width: 350px;

                .edit-therapist-form-therapist-email{

                    .field-set {

                        &.edit-therapist-field {

                            .custom-field-outer {

                                .custom-select-wrapper {
                                    min-height: 70px;

                                    .custom-select-display {
                                        min-height: 70px;
                                    }
                                }
                            }
                        }
                    }
                }

                .edit-therapist-form-title {
                    min-height: 70px;
                    color: #474849;
                    .field-set {

                        &.edit-therapist-field {

                            .custom-field-outer {

                                .base-input-wrapper.text-area-field {
                                    padding-top: 24px;

                                    .base-input-label.text-area-field {
                                        padding-top: 10px;
                                    }

                                    textarea {
                                        height: 45px;
                                        padding: 0 48px 10px 15px;
                                        color: #474849;
                                    }
                                }
                            }
                        }
                    }
                }

                .edit-therapist-form-message {

                    .field-set.edit-therapist-field {

                        .base-input-wrapper.text-area-field {
                            padding-top: 0;
                            height: 222px;
                            padding-top: 24px;

                            .base-input-label.text-area-field {
                                    width: 100%;
                                    padding-left: 15px;
                                    display: flex;
                                    padding-top: 10px;
                                    height: 100%;
                                    left: 0;
                            }

                            textarea {
                                padding: 0 48px 10px 15px;
                                color: #474849;
                            }
                        }
                    }
                }

                .more-options {
                    strong {
                        margin: 30px 0 0;
                        display: block;
                    }

                    .more-options-emails-cc-bcc {

                        .field-set.edit-therapist-field {

                            .custom-field-outer {

                                .base-input-wrapper {

                                    .chip-input.cc-email,
                                    .chip-input.bcc-email {
                                        display: flex;
                                        padding: 25px 0 0 15px;

                                        .chip-list-wrapper {

                                            .chip-element{
                                                margin: 2px 0;

                                                span {
                                                    background-color: #E0E0E0;
                                                    padding: 2px;
                                                    font-size: 10px;
                                                    line-height: 13px;
                                                    font-weight: 600;
                                                }
                                            }
                                            input {
                                                height: 100%;
                                                padding: 6px 15px;
                                            }
                                        }
                                    }

                                    .chip-input.bcc-email {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .field-set.edit-therapist-field {

                    .custom-field-outer {

                        .custom-select-wrapper,
                        .base-input-wrapper {
                            min-height: 50px;
                            height: 100%;

                            .base-input-label {

                                &.text-area-field {
                                    top: 0;
                                    transform: none;
                                }
                            }

                            .chip-input {

                                &.cc-email {
                                    width: 100%;
                                }

                                input {
                                    padding: 7px 15px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.details-email {

    .right-side-bar {

        .right-side-bar-header {

            .right-side-bar-title{
                color: #474849;
            }

            .actions {

                .base-text-btn {

                    &.white {
                        position: absolute;
                        left: 23px;
                        width: 35px;
                        height: 35px;
                        font-weight: 500;
                        @include flex-center-center;
                    }

                }

                .icon-close:before {
                    font-size: 24px;
                }

                button {
                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                .custom-small-dropdown-options-list {
                    z-index: 1;
                }
            }
        }

        .right-side-bar-body {
            position: relative;
            padding: 0 0 81px;
            overflow-y: hidden;

            .therapist-email-details {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow-y: auto;
                padding: 23px;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    height: 28px;
                    width: 18px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0px 0px 10px 10px transparent;
                    border: solid 0px transparent;
                }

                &::-webkit-scrollbar-thumb:vertical {
                    box-shadow: inset 0 0 10px 10px #D8D8D8;
                    border-top: solid 10px transparent;
                    border-left: solid 7px transparent;
                    border-bottom: solid 10px transparent;
                    border-right: solid 7px transparent;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
                }

                .therapist-email-detail {

                    .email-header {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 16px;
                        color: #474849;
                        font-size: 16px;
                        line-height: 19px;

                        .custom-small-dropdown-button.default-small-btn-with-icon.icon-options {
                            border: none;
                        }

                        .details-email-header {
                            @include flex-center-center;
                        }

                        .details-email-header-avatar {
                            width: 35px;
                            min-width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            background-color: #e0e0e0;
                            margin-right: 10px;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 15px;
                            @include flex-center-center;
                        }

                        .details-email-header-sender-send-to {
                            min-width: 320px;

                            .email-sender,
                            .email-send-to {
                                color: #474849;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 18px;
                            }

                            .email-send-to {
                                font-weight: 500;
                            }
                        }

                        .email-created-date {
                            opacity: 0.8;
                            color: #474849;
                            font-size: 10px;
                            font-weight: 600;
                            line-height: 13px;
                            @include flex-center-center;
                        }
                    }
                    .email-header-message {
                        padding: 0 68px;
                        word-break: break-word;
                        display: flex;
                        flex-direction: column;

                        div {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 19px;
                        }
                    }
                }

                .therapist-email-detail.collapse {

                    .therapist-email-details-icon-down {
                        transform: rotate(360deg);
                        display: inline-block;
                        color: #0192C9;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-left: -10px;
                    }
                }

                .therapist-email-detail.show {

                    .therapist-email-details-icon-down {
                        transform: rotate(270deg);
                        display: inline-block;
                        color: #9E9E9E;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-left: -10px;
                    }

                }
            }
        }
    }
}

.content {
    &.compressed {

        .emails-tab {

            .table-results-counter {
                left: 290px;
            }
        }
    }
}

.content {

    .emails-tab {

        .table-results-counter {
            left: 100px;
        }
    }
}
