.badge-top-filter {
  height: 50px;
  min-width: 120px;
  padding: 8px 9px 6px 10px;
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  cursor: pointer;
  margin-right: 16px;
  user-select: none;
  margin-top: 14px;
  &__title {
    color: #474849;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
  }
  &__body {
    color: #474849;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.badge-top-filter--active {
  background-color: #e5f4f9;
  border: 1px solid #0192C9;
}
