.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .two-column {
    flex: 1;
    width: 100%;
    >div {
      width: 50%;
    }
  }
}

.card-wrapper--vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 391px;
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  min-width: 260px;
  max-width: 371px;
  flex-grow: 1;
  color: #474849;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
    border-bottom: 1px solid #E0E0E0;

    color: #474849;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    .icon {
      font-size: 24px;
    }
  }

  &__edit {
    cursor: pointer;
  }

  &__actions {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }

  &__title {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
    }
  }

  &__body {
    padding: 10px 14px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    padding-top: 8px;
    padding-left: 8px;
    color: #474849;
    width: 100%;
    display: flex;
  }

  &__item-body {
    flex: 1;
  }

  &__item-icon {
    font-size: 24px;
  }

  &__item-icon.clickable {
    cursor: pointer;
  }

  &__subtitle, .base-input-label  {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
    color: #474849;
  }

  &__text, .view-value {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    word-break: break-word;
    padding-bottom: 5px;
    color: #474849;

    &.highlited{
      width: fit-content;
      background-color: #E0E0E0;
      color: #474849;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
      text-transform: uppercase;
      padding: 2px 4px;
      margin-top: 4px;
    }
  }
  .view-field {
    width: 100%;
    min-height: 28px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.card.w-100 {
  max-width: 100%;
  min-width: 100%;
}

.card.no-border {
  border: 0;

  .card__header {
    border-bottom: 0;
  }
}



.details-card {
  .card__header {
    padding: 0 0 8px 0;
  }

  .card__body {
    padding: 0;
  }

  .card__item {
    margin-top: 6px;
    padding: 8px 0 5px 5px;
  }
}

.void-card {
  .card__header {
    padding: 0;
  }

  .card__body {
    padding: 0;
    >div {
      width: 100%;
    }
  }

  .card__item {
    margin-top: 6px;
    padding: 8px 0 5px 0;
  }

  .view-field  {
    min-height: 50px;
    padding: 8px 0 5px 0;

    .base-input-label {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
    }

    .view-value {
      letter-spacing: 0;
      line-height: 22px;
    }

    &.display-internal-note {

      .view-value {
        word-wrap: break-word;
      }
    }
  }
}

.card-item {
  display: flex;
  .card-files {
    flex: 1;
  }
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.void-card.pl-34 {
  .card__body {
    padding: 14px 0 14px 34px;
  }
}

.void-card.pl-34-view {
  .card__body {
    padding: 6px 0 6px 34px;
  }
}

.two-column-card {
  width: 47%;
  margin-right: auto;
}

.two-column-card.pl-0 {
  padding-left: 0;
}

