.base-input-wrapper,
.base-input-wrapper-with-ico,
.base-date-picker-wrapper-with-ico,
.custom-select-wrapper {
    position: relative;
    border: 1px solid #e0e0e0;
    color: #474849;
    height: 50px;
    display: flex;

    &.with-error {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color:rgb(233, 74, 74);
            z-index: 1;
        }
    }

    &.focused {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #0192C9;
            z-index: 1;
        }
    }

    .base-input-label,
    .base-date-picker-label {
        position: absolute;
        font-size: 16px;
        font-weight: 400;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        transition: all .3s ease;
        z-index: 1;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.floated {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            top: 13px;
            line-height: 13px;
            transition: all .3s ease;
        }
    }

    input {
        border: none;
        width: 100%;
        height: fit-content;
        padding: 0px 15px;
        padding-top: 10px;
        color: #474849;
        font-size: 16px;
        outline: none;

        &::placeholder {
            opacity: 0;
        }
    }
}

.base-textarea-wrapper {
    position: relative;
    border: 1px solid #e0e0e0;
    height: 100px;
    color: #474849;
    display: flex;
    padding: 30px 10px 10px 15px;

    &.with-error {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color:rgb(233, 74, 74);
        }
    }

    &.focused {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #0192C9;
        }
    }

    .base-textarea-label {
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        top: 20px;
        left: 15px;
        transition: all .3s ease;

        &.floated {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            top: 13px;
            line-height: 13px;
            transition: all .3s ease;
        }
    }

    textarea {
        border: none;
        width: 100%;
        height: 100%;
        resize: none;
        color: #474849;
        font-size: 16px;
        outline: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .clear-filter {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}


.base-input-wrapper-with-ico,
.base-date-picker-wrapper-with-ico {

    .base-input-label,
    .base-date-picker-label {
        left: 50px;
    }

    .icon {
        font-size: 25px;
        cursor: pointer;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        position: absolute;
    }

    input {
        padding: 20px 0 0 48px;
    }


}

.custom-field-outer {
    color: #474849;

    .error {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

.base-input-wrapper {
    &.text-area-field {
        height: 100px;
        padding-top: 20px;

        textarea {
            width: 100%;
            border: none;
            outline: none;
            padding: 0 15px 10px;
            resize: none;
            font-size: 16px;
        }
        span{
            top: 25px;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker {
            &__input-container {
                height: 100%;
            }
        }
    }

    .react-datepicker-popper {
        width: 100%;
        z-index: 2;

        .react-datepicker {
            width: 100%;

            &__month-container,
            &__time-container,
            &__time-box {
                width: 100% !important;
            }

            &__day-names,
            &__week {
                display: flex;
                justify-content: space-evenly;
            }
        }
    }

    .check-box-field {
        width: 100%;
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;

        label {
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);

            +span {
                position: absolute;
                top: 22px;
                left: 15px;
            }
        }
    }

    &.classic {
        border: none;
        .base-input-label {
            display: none;
        }
        .check-box-field {
            display: flex;
            align-items: center;
            label {
                position: relative;
                top: unset;
                right: unset;
                transform: unset;
                bottom: 1px;
                margin-top: 1px;
            }
            span {
                position: relative;
                top: 0;
                left: 0;
                margin-left: 10px;
            }
        }
        .check-box-field.left-label {
            display: flex;
            span {
                order: 0;
                margin-left: 0;
                margin-right: 10px;
            }
            label {
                order: 1;
            }
        }
    }
}

.right-side-bar-body {

    .field-set {
        margin-bottom: 15px;

        .custom-field-outer {

            .base-input-wrapper,
            .custom-select-wrapper{
                margin-bottom: 0;
            }
        }

        .error-message-for-input {
            position: relative;
            bottom: 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 500;
            margin-top: 4px;
            display: block;
        }

        &.multi-phone-select {

            .error-message-for-input {
                bottom: -4px;
            }
        }
        .input-file-wrapper  {
            margin-bottom: 0;
        }
    }
}

.base-input-wrapper-with-ico {
    background: #ffffff;
}

.small-search-filter {
    & .base-input-wrapper-with-ico {
        height: 35px;
    }
    & .floated.base-input-label {
       display: none;
    }
    & .base-input-label {
        left: 40px;
    }
    & .icon {
        font-size: 20px;
    }
    & input {
        padding: 10px  0 0 40px;
    }
}
