@import '../fonts/icomoon-variables';

.main-table-wrapper {
    width: calc(100% - 30px);
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 25px 25px 0 25px;
    position: relative;
    transition: all .3s ease;

    &.table-info-open {
        width: calc(100% - 600px);
        transition: all .3s ease;
    }

    .table-shadow-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: 10000;
    }

    &::-webkit-scrollbar {
        border-top: 1px solid #E0E0E0;
        height: 28px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        border: solid 10px transparent;
    }

    &::-webkit-scrollbar-thumb:horizontal{
        box-shadow: inset 0 0 10px 10px #D8D8D8;
        border-top: solid 12px transparent;
        border-bottom: solid 12px transparent;
        border-left: solid 160px transparent;
        border-right: solid 8px transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
    }

    .table-header-wrapper,
    .table-row-wrapper {
        display: flex;
        align-items: center;
    }

    .table-row-wrapper {
        &.with-action-bar {
            margin-right: 50px;
        }

        &.clickable {
            cursor: pointer;
        }
    }

    .table-header-wrapper {
        .small-td {
            background-color: initial;
        }

        .column-filter-wrapper {
            position: absolute;
            top: -10px;
            left: 0;
            bottom: 0;
            right: 0;
            border: 1px solid #E0E0E0;
            border-left: 2px solid #0192C9;
            z-index: 10;
            background-color: #fff;
            padding: 15px 10px 0;

            &.small-filter {
                width: 120px;
            }

            input {
                width: 100%;
                height: 100%;
                position: relative;
                border: none;
                outline: none;
                font-size: 16px;
            }

            span {
                position: absolute;
                z-index: 1;
                top: 8px;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .table-head-data {
            height: 100%;
            padding: 11px 14px;
        }
    }

    .table-data-wrapper,
    .table-data {
        min-width: 210px;
        width: 100%;
        height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        color: #474849;

        &.date-td {
            max-width: 100%;
            min-width: 120px;
        }

        .table-data-inner-text {
            position: absolute;
            width: calc(100% - 10px);
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.visisble-overflow {
                overflow: initial;
            }
        }
    }

    .table-data {
        background-color: #F8F8F8;
        border: 1px solid #fff;
        padding: 11px 14px;
    }

    .small-td {
        min-width: 70px;
        max-width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sub-small-td {
        min-width: 50px;
        max-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .table-body-wrapper {
        height: calc(100% - 40px);
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: fit-content;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .subgrid-table {
        .table-data {
            background-color: #e6e6e6;
            border: 1px solid #fff;
            padding: 11px 14px;
        }
    }
}

.hidden-table-message {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 20px;
    padding: 20px;
}

.selected-filters-counter {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: #0192C9;
    color: #fff;
}

.column-filter-options-wrapper {
    width: 270px;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
    position: absolute;
    left: 0;
    height: 350px;
    overflow-y: auto;
    z-index: 10;
    top: 105%;
    cursor: default;
    overflow: hidden;

    &.left-position {
        right: 0;
        left: auto;
  }

    .column-filter-options-wrapper-header {
        height: 50px;
        border-bottom: 1px solid #E0E0E0;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cancel-filter-btn,
        .save-filter-btn {
            border: none;
            opacity: 0.5;
            color: #474849;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            background-color: #fff;
            cursor: pointer;
            outline: none;
        }

        .save-filter-btn {
            color: #0192C9;
            opacity: 1;
        }

        span {
            font-weight: 600;
        }
    }
}

.column-filter-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    pointer-events: visible;
}

.innerTEXT {
    color: #000
}

.column-filter-options-body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 10px 0;

    .column-filter-option {
        cursor: pointer;
        padding: 12px;
        color: #474849;
        font-weight: 500;

        label {
            span {
                padding-left: 30px;
                white-space: nowrap;
                display: block;
                width: 200px;
            }
        }

        &:hover {
            background-color: #E5F4F9;
        }
    }
}


.table-results-counter {
    position: fixed;
    bottom: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
}

.main-table-wrapper {
    .table-data {
        &.fixed-column{
            position: fixed;
            right: 30px;
            width: 210px;
        }
    }
}


// Fix layout of page
.table-page {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: relative;

    @media (max-width: 1400px) {
        .table-header-top-bar {
            flex-wrap: wrap;

            .table-header-actions {
                margin: 16px 0 0;

                .options-button-wrapper {

                    .export-btn {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .ag-pinned-right-cols-container {

        .ag-row {

            .ag-cell {

                .ag-react-container {
                    text-align: center;
                }
            }
        }
    }

    .ag-table-wrapper {
        padding: 0!important;
    }
}

.ag-table-wrapper {
    height: unset!important;
    overflow: unset!important;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
}

.table-page {
    height: 100%;
}

.dote-cell {
    display: flex!important;
    justify-content: center;
    align-items: center;
    padding: 0!important;
    .custom-select-display > div {
        display: flex;
    }
    .ag-react-container {
        .custom-field-outer {
            .custom-select-wrapper {
                &::after {
                    content: '';
                }
                .custom-select-display {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    .icon  {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
