// Fix layout of page
.request-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    position: relative;

    & .table-header-top-bar {
        padding: 16px 13px 16px 0;
        border-bottom: 1px solid #e0e0e0;
    }

    .ag-pinned-right-cols-container {
        .ag-row {
            .ag-cell {
                .ag-react-container {
                    text-align: center;
                }
            }
        }
    }

    .badge-wrapper {
        padding-left: 25px;
    }

    .ag-table-wrapper {
        padding: 0;
    }

    .ag-header {
        .ag-header-viewport {
            .ag-header-container {
                padding-left: 25px;
            }
        }
    }

    .ag-center-cols-clipper {
        .ag-center-cols-viewport {
            .ag-center-cols-container {
                padding-left: 25px;
            }
        }
    }
}

// Render functions

.request-status {
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    padding: 2px 4.5px;
    text-transform: uppercase;
    background-color: rgba(248, 248, 248, 0.4);

    &.fixed-width {
        display: inline-block;
        min-width: 70px;
    }

    &.complete {
        background-color: rgb(35, 87, 2);
    }

    &.new {
        background-color: rgb(0, 122, 255);
    }

    &.accepted {
        background-color: #43910a;
    }

    &.pendingauth,
    &.awaiting-auth {
        background-color: rgb(189, 16, 224);
    }

    &.declined {
        background-color: rgb(255, 89, 95);
    }

    &.pending,
    &.pendinglegacy,
    &.open {
        background-color: #f5a623;
    }

    &.hold,
    &.inprogress,
    &.waiting {
        background-color: #474849;
    }

    &.assigned {
        background-color: #d58f0a;
    }

    &.declined,
    &.closed {
        background-color: rgb(255, 89, 95);
    }
}

//styles for  notes request details

.details {
    &.request {
        .notes-tab {
            width: 100%;

            .table-header-top-bar {
                width: 100%;
            }
            .field-data-wrapper {
                padding-right: 0;

                .field-data {
                    width: 100%;
                }
            }
        }
    }
}
