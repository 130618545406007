.text-button {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    height: 35px;
    padding: 0px 14px;
    display: flex;
    align-items: center;

    &.white {
        border: 1px solid #E0E0E0;
        color: #474849;
        background-color: #fff;

        &:hover {
            background-color: #f5f5f5;
        }
    
        &:active {
            background-color: #E0E0E0;
        }
    }

    &.blue {
        border: none;
        color: #fff;
        background-color: #0192C9;

        &:hover {
            background-color: #34a8d4;
        }

        &:active {
            background-color: #80c8e4;
        }
    }

    &:disabled {
        background-color: #E0E0E0;
        pointer-events: none;
        color: #474849;
    }
}

.infinite-logo {
    height: 50px;
    width: 50px;
    align-self: center;
    background-image: url('../images/Infinite-logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    margin-bottom: 25px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;

    :hover, :focus {
        text-decoration: none;
    }
}