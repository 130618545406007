.counter {
    height: 16px !important;
    min-width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    padding-left: 4.5px;
    padding-right: 4.5px;
    color: #ffffff !important;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;
    border-radius: 8px;
    font-weight: 600 !important;
    background-color: #ff595f !important;
}

.ml-64 {
    margin-left: 64px;
}

.ml-10 {
    margin-left: 10px;
}
