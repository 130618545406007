.patients-in-zip-dialog-wrapper {
    z-index: 1001;
}

.patients-in-zip-dialog-content {
    background-color: #ffffff;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.15);
}

.patients-zip-dialog-header {
    display: flex;
    padding: 23px 20px 14px 14px;

    &__left {
        align-items: center;
        display: flex;
        justify-content: flex-start;
    }
    &__right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex: 1;
    }
    &__title {
        color: #474849;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
    }
    &__icon {
        font-size: 24px;
        margin-right: 14px;
    }
    &__close {
        font-size: 24px;
        cursor: pointer;
    }
}

.patients-zip-table {
    height: 485px !important;
    .ag-center-cols-viewport {
        height: calc(100% + 24px)!important;
    }
}

.patients-in-zip-content {
    width: 100%;
}
