.filter-section {
  display: flex;
  .filter {
    align-items: center;
    display: flex;
  }

  @media screen and (max-width: 1500px) {
    .date-filter > div:first-child {
      margin-right: 8px;
    }
    .department-filter {
      margin-right: 8px;
    }
    .overdue-filter {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1439px) {
    flex-wrap: wrap;
    .filter {
      align-items: center;
      display: flex;
      width: 100%;
      max-width: 310px;
      >div {
        flex: 1;
        width: 100%;
      }
    }
    .filter:first-child {
      width: 100%;
      flex: 1;
      max-width: unset;
      min-width: 100%;
      margin-bottom: 11px;
    }
    .filter:not(first-child){
      width: 50%;
    }
  }
}

.filter-section.cols-3 {
  display: flex;
  flex-wrap: wrap;
  >div:first-child {
    width: 100%;
    flex: 1 1 auto;
  }
  >div {
    margin-bottom: 10px;
    flex: 1;
  }
}

.my-follow-up-filters {
  @media screen and (max-width: 1439px) {
    >div:nth-child(2) {
      max-width: 620px;
      .date-filter-field {
        max-width: 310px;
      }
    }
    .filter:not(first-child) {
      width: 100%;
    }
  }
}
