.patient-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  .button-block {
    >button {
      margin-left: 14px;
    }
  }

  .navbar-left {
    min-width: 250px;
  }

  .card {
    border: none;

    &__header {
      border: none;
    }
  }

  .card-wrapper--enrollment {
    min-width: 650px;
  }

  header{
    flex: 0 0 auto;
 }

 .tabs {

   &.horizontal {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .tabs-nav {

      &.nav {

        &.navbar-nav {

          &.navbar-left {
            flex: 0 0 auto;
          }
        }
      }
    }

    .tabs-active-content {
      flex: 1 0 auto;

      .table-page {
        height: 100%;
        min-height: 100%;
      }
    }
   }
  }
}

.patient-contact {
  .card {
    width: 100%;
    max-width: 100%;
    &.patient-contact-form {
      .card__body {
        width: 100%;
        padding: 14px 0 14px 0;
      }
    }
  }
}
