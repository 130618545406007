.notes-page {

    header {

        .header-wrapper {
            height: fit-content;
            padding: 24px 34px;
            border-bottom: none;

            .title-wrapper {

                h1 {
                    color: #474849;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 37px;
                }
            }
        }
    }

    .table-header-top-bar {
        padding: 0 34px 16px 34px ;
    }

    .table-header-wrapper {
        display: none;
    }

    .table-body-wrapper {
        min-width: 100%;

        .table-row-wrapper {

            .table-data:first-child {
                display: none;
            }
            .table-data:not(:first-child) {
                height: 100%;
                background-color: #FFFFFF;

                .table-data-inner-text {
                    position: relative;
                    transform: translateY(0);
                    top: 0;

                    .note-cell {

                        .note-cell-content {
                            width: 100%;

                            .note-content {
                                padding-left: 44px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
