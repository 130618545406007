body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-tooltip-lite {
  background: #474849;
  color:#FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
}

.react-tooltip-lite-arrow {
  border-color: #474849;
}

.hide {
  display: none;
}

#root {
  display: flex;
  height: 100vh;
}

#root .infinity-app {
  width: 100%;
  flex: 1;
}

  div[data-rbd-draggable-id], .field-set, .field-list-item, .entity-from, .edit-form, .outline-none {
    outline: none;
  }
