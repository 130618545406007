.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 0 18px 0;
  cursor: move;
  .total-count {
    > strong {
      margin-right: 8px;
      color: #474849;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
     > span {
      font-weight: 600;
      height: 24px;
      max-height: 24px;
      min-width: 35px;
      color: #ffffff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      border-radius: 12px;
      background-color: #474849;
      line-height: 19px;
      padding-top: 4px!important;
    }
  }
  .action-buttons {
    margin-right: -8px;
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
  }
}
