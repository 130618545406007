.notifications-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 9;
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification-toast {
    background: #242626;
    transition: .3s ease;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    width: 365px;
    color: #fff;
    padding-right: 15px;
    padding-bottom: 15px;

    .notification-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 15px;
        padding-left: 15px;
        border-left: 2px solid #0192c9;

        .close-notification-btn {
            background-color: #404444;
            color: #909595;
            border-radius: 100px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
        }

        .notification-text {
            color: #e5f4f9;
            margin: 10px 0;
        }

        .notification-date {
            background-color: #fff;
            color: #474849;
            font-size: 12px;
            font-weight: 600;
            padding: 5px;
            width: 90px;
        }
    }

    .notification-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;

        .notification-snooze-select {
            background-color: transparent;
            width: 150px;
            box-sizing: border-box;

            .custom-select-description.floated {
                display: none;
            }
        }

        .custom-select-wrapper {
            box-sizing: border-box;
            border: 2px solid #c9cbce;
            color: #c9cbce;
            min-height: 28px;
        }

        .custom-select-display {
            min-height: 28px;
            box-sizing: border-box;
            padding: 0;
            padding-left: 5px;
        }

        .notificication-done-btn {
            background-color: #0192c9;
            color: #fff;
            text-align: center;
            width: 60px;
            height: 32px;
            line-height: 32px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}