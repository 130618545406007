.availability-portal-page {

  .available-therapist-filter__department {
    width: 190px;
    .custom-select-wrapper {
      width: 190px;
    }
  }

  .available-therapist-filter__status {
    .custom-select-wrapper {
      width: 150px;
    }
  }

  .group-toggle-mode {
    margin-right: auto;
  }

  .table-header-top-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 16px 34px;
    zoom: 0.93
  }

  .search-area {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    zoom: 0.93;

    .group-toggle-mode {
      margin-top: 10px;
    }

    .available-therapist-map .search-area-clear-filter {
      margin-left: 5px !important;
    }
  }

  .table-header-top-bar__left-section {
    margin-bottom: 10px;
    margin-right: auto;
  }

  .table-header-top-bar__left-section .custom-field-outer {
    margin-right: 5px;
  }

  .table-header-actions, .actions-left-group {
      display: flex;
      justify-content: flex-start;
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;

      button {
          white-space: nowrap;
      }
      .options-button-wrapper {
          flex: 0;
        .base-text-btn {
          margin-left: 10px;
        }
      }
      >button {
        margin-left: 5px;
        margin-right: 5px;
      }
  }

  .actions-left-group {
    .export-button {
      margin-right: 0;
    }
  }

  .field-set {
    width: unset;
  }

  .search-filter-inner {
    width: 270px;
    .base-input-label {
      left: 40px;
    }
    .base-input-wrapper-with-ico {
      width: 270px;
    }
    .base-input-wrapper-with-ico input, .base-date-picker-wrapper-with-ico input {
      padding: 20px 0 0 39px;
    }
  }

  .zip-code-assigned {
    width: unset;
    .base-input-wrapper-with-ico {
      width: 150px;
    }
  }

  .custom-select-display {
    padding-left: 10px;
  }

  .custom-select-description {
    left: 10px;
  }

  @media (max-width: 1400px) {
        .options-button-wrapper {
          .export-btn {
            margin-left: 10px!important;
          }
        }
  }


}
