.outline-none {
    outline: none;
}
.no-select-border .custom-select-wrapper  {
    border: 0;
}
.only-icon .custom-select-wrapper  {
    min-height: 20px;

    &.open {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 0;
            height: 0;
            background-color: unset;
        }

        .custom-select-display {
            background-color: #f5f5f5;
        }
    }

    .custom-select-display {
        min-height: 20px;
        padding: 4px;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .option-list {
        right: 0;
        left: unset;
    }

    .select-value {
        .icon {
            font-size: 24px;
        }
    }
    &:after {
        content: '';
        display: none;
    }
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}

@mixin option-list {
    position: absolute;
    width: 100%;
    max-height: 350px;
    min-height: max-content;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
    top: calc(100% + 5px);
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;

    &.multiselect {
        .option-item {
            span {
                margin-left: 30px;
            }
        }
    }

    .option-item {
        padding: 11px 20px;
        cursor: pointer;

        span {
            display: inline-block;
            white-space: nowrap;
            margin-left: 24px;
        }

        label:only-child span {
            margin-left: 0; //fix padding in county
        }

        &:hover {
            background-color: #E5F4F9;
        }
    }

    .custom-select-options-wrapper-header {
        height: 50px;
        min-height: 50px;
        border-bottom: 1px solid #E0E0E0;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cancel-select-header-btn,
        .save-select-header-btn {
            border: none;
            opacity: 0.5;
            color: #474849;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            background-color: #fff;
            cursor: pointer;
            outline: none;
        }

        .save-select-header-btn {
            color: #0192C9;
            opacity: 1;
        }

        span {
            font-weight: 600;
        }
    }

    .clear-btn-wrapper {
        border-bottom: 1px solid #E0E0E0;
        padding-left: 20px;
        height: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;

        .clear-btn {
            background: transparent;
            border: none;
            color: #0192C9;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            padding: 0;
            text-align: left;
        }
    }
}
.custom-select-wrapper {
    min-height: 50px;
    height: 100%;
    border: 1px solid #E0E0E0;
    flex-direction: column;
    color: #474849;
    position: relative;

    &.open {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 2px;
            height: 100%;
            background-color: #0192C9;
        }

        .custom-select-display {
            background-color: #f5f5f5;
        }
    }

    .custom-select-display {
        min-height: 48px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        cursor: pointer;
        padding: 20px 30px 8px 15px;
        outline: none;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .custom-select-description {
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        pointer-events: none;
        transition: all .3s ease;

        &.floated {
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            top: 13px;
            transition: all .3s ease;
        }
    }

    .custom-select-value {
        font-size: 16px;
        line-height: 19px;
    }
    .selected-counter{
        margin-right: 5px;
    }

    .option-list {
       @include option-list
    }

    &:after {
        content: $icon-dropdown;
        position: absolute;
        font-family: 'icomoon';
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        pointer-events: none;
    }
}
.number-select-wrapper {
    &:after {
        display: none;
    }
}


.custom-dropdown.option-list {
    @include option-list;
   // z-index: 1020;
    width: unset;
}


.button-with-icons {
    display: flex;
    align-items: center;
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    .icon {

        padding: 3px 11px;

        &.icon-person {
            padding-left: 7px;
            &::before {
                font-size: 20px;
            }
        }

        &.icon-dropdown {
            padding-right: 7px;
            &::before {
                font-size: 20px;
            }
        }
    }
}

.phone-type {
    font-weight: 600;
}
