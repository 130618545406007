.edit-therapist-form {
    width: 350px;
}

.phone-wrapper {
    display: flex;

    .view-field {
        width: 70%;
    }
}

.main-item {
    &::after {
        width: fit-content;
        background-color: #E0E0E0;
        color: #474849;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        content: 'Main';
        text-transform: uppercase;
        padding: 2px;
        margin-top: 18px;
        height: fit-content
    }

    &.without-main-flag{
        &::after{
            display: none;
        }
    }
}

.card-wrapper--vertical.therapist-details {
    min-width: 550px;
    max-width: 623px;
}
.card.therapist-details-departments,
.card.therapist-details-avatar {
    min-width: 550px;
    max-width: 623px;
}

.therapist-details-avatar {
    .avatar-comment {
        width: 343px;
        margin-right: auto;
        opacity: 0.7;
        color: #474849;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0;
        line-height: 13px;
    }
    .update-therapist-profile-photo {
        margin-top: 20px;
        width: 100%;
        .image-preview {
            margin-top: 0;
        }

        >div:first-child {
            display: none;
        }
        .update-section {
            margin-top: 0;
            display: flex;
            align-items: center;
        }
        .image-upload-button {
            margin-top: 0;
        }
    }
}

.provider-grid-wrapper.three-column {
    display: grid!important;
    grid-template-columns:  repeat(4, 1fr);
    width: 100%;
    max-width: 1880px;
    row-gap: 10px;
    gap: 10px;
    > div {
        margin: 0;
    }

    .box-1 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: 1;
        min-width: 300px!important;
    }

    .box-2 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 2;
        grid-column-end: 2;
        max-width: 100%
    }

    .box-3 {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 3;
    }
}
.provider-grid-wrapper.three-column.lg-card {
    grid-template-columns:  repeat(3, 1fr);
    width: 90%;
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 100%;
        max-width: 100%;
    }
    .box-3 {
        grid-row-start: 11;
        grid-row-end: 11;
        grid-column-start: 1;
        grid-column-end: 3;
        max-width: 1200px;
    }

}
.provider-grid-wrapper.three-column.md-card {
    grid-template-columns:  repeat(3, 1fr);
    .box-1 {
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    .box-3 {
        grid-row-start: 11;
        grid-row-end: 11;
        grid-column-start: 1;
        grid-column-end: 3;
        max-width: 1200px;
    }
}
.provider-grid-wrapper.three-column.sm-card {
    grid-template-columns:  repeat(2, 1fr);
    .box-1 {
        width: 100%;
        max-width: 100%;
        min-width: 200px!important;
    }
    .box-2 {
        width: 100%;
        max-width: 100%;
        min-width: 500px;
    }
    .box-3 {
        grid-row-start: 11;
        grid-row-end: 11;
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}
.provider-grid-wrapper.three-column.extra-sm-card {
    grid-template-columns:  repeat(1, 1fr);
    .box-1 {
        grid-row-end: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-2 {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .box-3 {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}
.provider-general-tab {
    .tabs-nav {
        max-width: 170px!important;
    }
    .tabs-active-content {
        padding-left: 24px!important;
        padding-right: 12px;
    }
}



