.map-body {
    flex: 1;
    z-index:2;
    position: relative;
    .therapists-on-map {
        width: 100%;
        height: 100%;
    }

    div[role="dialog"] {
        padding: 0!important;
        border-radius: 0!important;
        box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1)!important;


        > div {
            overflow: hidden!important;
        }
        > button {
            top: 5px !important;
            right: 12px !important;
            >img {
                width: 24px!important;
                height: 24px!important;
            }
        }
    }
}

.create-direction-btn {
    position: absolute;
    left: 5px;
}

.clear-direction-btn {
    position: absolute;
    right: 5px;
}
