.base-input-wrapper{
    position: relative;
    border: 1px solid #e0e0e0;
    color: #474849;
    height: 50px;
    display: flex;


&.date{
    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 4px;
        width: 100%;

        .react-datepicker {
            width: 100%;

            &__time-container {
                width: 100%;
            }

            &__day-names {
                display: flex;
                justify-content: space-evenly;
            }

            &__week {
                display: flex;
                justify-content: space-evenly;
            }

            &__month-container {
                width: 100%;
            }

            &__time {
                width: 100%;
            }

            &__time-box {
                text-align: initial;
                margin: 0;
                width: 100%;
            }

            &__navigation {
                outline: none;
            }
        }
    }
}

    &.focused {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #0192C9;
            z-index: 1;
        }
    }

    &.has-error {
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color:rgb(233, 74, 74);
            z-index: 1;
        }
    }

    .base-input-label {
        position: absolute;
        font-size: 16px;
        font-weight: 400; // change according design of request form
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        transition: all .3s ease;
        z-index: 1;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.floated {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            top: 13px;
            line-height: 13px;
            transition: all .3s ease;
        }
    }

    input {
        border: none;
        width: 100%;
        height: 100%;
        padding: 0px 15px;
        padding-top: 7px;
        color: #474849;
        font-size: 16px;
        outline: none;
    }

    .react-datepicker__day{

        &.react-datepicker__day--keyboard-selected {
            background: none;
            color: black;
        }
    }
}

.custom-field-outer {
    color: #474849;

    .error {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

.infinite-input-field-outer {
    display: flex;
    align-items: center;
    cursor: pointer;
    .base-input-wrapper {
        width: 100%;
    }
    .view-password {
        font-size: 24px;
        margin-left: -36px;
        z-index: 2;
    }
}

