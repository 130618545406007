.react-grid-layout {
    position: relative;
    background-color: #FBFBFB;
    .table-page {
        min-height: unset;
    }
    .react-draggable {
        transition: width 0.1s, height 0.1s;
        box-sizing: border-box;
        border: 1px solid #D7D7D7;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
        padding: 0 21px 0 21px;
    }
    .ag-header .ag-header-viewport .ag-header-container {
        padding-left: 0!important;
    }
    .ag-center-cols-clipper .ag-center-cols-viewport .ag-center-cols-container {
        padding-left: 0!important;
    }
    .new-table-header-top-bar, .ag-body-horizontal-scroll, .ag-status-bar {
        padding-left: 0!important;
    }
    .ag-table .ag-row-loading {
        margin-left: 0!important;
    }
    .ag-table-main {
        margin-top: 16px;
    }
    .default-selected-label {
        position: relative;
        top: 26px;
        left: 16px;
    }
    .multiple-autocomplete .selected {
        padding-right: 20px;
    }
    .base-input-wrapper.multiple-autocomplete {
        min-height: 50px;
        max-height: 87px;
        overflow: hidden;
    }
    .total-row-bar {
        padding-top: 4px;
    }
    .ag-full-width-container {
        box-shadow: none;
    }

    .ag-center-cols-viewport::-webkit-scrollbar,
    .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
    .ag-horizontal-left-spacer::-webkit-scrollbar,
    .ag-horizontal-right-spacer::-webkit-scrollbar {
        border-top: 1px solid transparent;
        height: 9px;
    }

    .ag-center-cols-viewport::-webkit-scrollbar,
    .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
    .ag-horizontal-left-spacer::-webkit-scrollbar-track,
    .ag-horizontal-right-spacer::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px 0px transparent;
        border: none;
        border-top: solid 10px transparent;
        border-bottom: solid 10px transparent;
    }

    .ag-center-cols-viewport::-webkit-scrollbar,
    .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:horizontal,
    .ag-horizontal-left-spacer::-webkit-scrollbar-thumb:horizontal,
    .ag-horizontal-right-spacer::-webkit-scrollbar-thumb:horizontal {
        border-top: solid 1px transparent;
        border-bottom: solid 4px transparent;
        border-left: solid 0 transparent;
        border-right: solid 0 transparent;
    }

    .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover,
    .ag-horizontal-left-spacer::-webkit-scrollbar-thumb:hover,
    .ag-horizontal-right-spacer::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
    }

    .left-shadow {
        display: none!important;
    }
}
