.autocomplete,
.custom-autocomplete {
    &.custom-select-wrapper {
        min-height: 50px;
    }

    .option-list,
    &.option-list {
        padding-bottom: 0;

        .search-wrapper {
            padding: 0 20px;

            &.focused {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 20px;
                    height: 35px;
                    width: 2px;
                    background-color: #0192c9;
                }
            }
        }

        .search {
            width: 100%;
            min-height: 35px;
            border: 1px solid #e0e0e0;
            display: block;
            outline: none;
            font-size: 16px;
            padding-left: 10px;
            margin-bottom: 10px;
        }
    }

    .options-list,
    &.options-list {
        display: flex;
        flex-direction: column;
        max-height: 315px;
      //  overflow-y: auto; //fix double scroll in dropdown
    }
    .autopopulate {
        padding-top: 16px;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 0 24px 0 24px;
        }
        &-text-wrapper {
            display: flex;
            margin-left: 24px;
            span.bold {
                font-weight: bold;
                margin: 0;
            }
        }
        .create-new {
            color: #0192c9;
            font-size: 16px;
            cursor: pointer;
            font-weight: 600;
        }
        .option-item-value {
            margin-left: 0;
        }

        .option-item {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 11px 0px;

            &-btn {
                display: none !important;

                color: #0192c9;
                font-weight: 500;

                position: absolute;
                right: 30px;
                top: 50%;
                transform: translate(0%, -50%);
            }

            &:hover {
                .option-item-btn {
                    display: block !important;
                }
            }
        }
        .option-item.didnt-find {
            .option-item-btn {
                display: block !important;
            }
        }
    }
}
.multiple-autocomplete-with-tabindex {
    flex-wrap: wrap;
}
.multiple-autocomplete,
.custom-autocomplete {

    .selected {
        border: none;
        width: 100%;
        padding: 7px 15px;
        color: #474849;
        font-size: 16px;
        outline: none;
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        margin-top: 15px;
        margin-right: 3px;
        padding: 0 3px;
        font-size: 10px;
        background-color: #e0e0e0;
        display: flex;
        width: max-content;
        font-weight: 600;
        align-items: center;
        height: 20px;
    }

    .item-content {
        overflow: hidden;
    }

    .item-delete-btn {
        display: inline-block;
        padding-right: 0;
        border: none;
        background-color: initial;
        cursor: pointer;
        outline: none;
    }

    .search {
        z-index: -1;
    }
}

// Unselect
.autocomplete__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.autocomplete {
    &__unselect-button {
        padding: 11px 20px;
        color: #0192c9;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
    }
}


.base-input-wrapper {
    input[disabled] {
        background: #E0E0E0;
    }
    &.multiple-autocomplete {
        min-height: 50px;
        height: 100%;
    }
}


.custom-autocomplete  {
    //position: absolute;
    width: 100%;
    max-height: 350px;
    min-height: max-content;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 2px rgba(31,37,50,0.1);
    //top: 110%;
    //left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;

    &.option-list .search-wrapper {
        padding: 0 20px;
    }
    .option-item {
        padding: 11px 20px;
        cursor: pointer;

        span {
            margin-left: 30px;
            display: inline-block;
            white-space: nowrap;
        }

        &:hover {
            background-color: #E5F4F9;
        }
    }
}
.custom-select-wrapper.autocomplete {
    .base-input-wrapper {
        input {
            padding-right: 35px;
        }
    }
}
.hide-blue-border.base-input-wrapper.focused::before {
    width: 0!important;
}
.show-selected-counter {
    .default-selected-label {
        display: flex;
        align-items: center;
        padding-left: 14px;
        padding-top: 16px;
    }
}
.autocomplete-actions {
    margin-bottom: 15px;
}

.option-roles {
    font-size: 12px;
    color: #474849;
    margin-top: 5px;
    line-height: 18px;
    span {
        font-weight: bold;
        margin-top: -5px;
    }
}
