@mixin close-modal-button {
    border: 1px solid #e0e0e0;
    width: 35px;
    height: 35px;
    font-family: "icomoon";
    color: #474849;
    font-size: 25px;
    background-color: transparent;
    background-color: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
  }

.document-management-page {

    header {

        .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            border: none;
        }
    }

    .tabs {

        &.horizontal{
            overflow-x: hidden;

            .tabs-nav {

                &.nav {

                    &.navbar-nav {

                        &.navbar-left {
                            position: relative;
                            padding-left: 34px;
                        }
                    }
                }
            }

            .tabs-nav:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: #E0E0E0;
                bottom: -1px;
                left: 34px;

            }

        }

        .tabs-active-content {

            .table-header-top-bar {
                position: relative;
                padding: 20px 34px;
                border: none;

                .table-header-actions {

                    .base-text-btn {
                        margin: 0 0 0 20px;
                    }
                }
            }

            .table-header-top-bar:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: #E0E0E0;
                bottom: 0;
                left: 34px;
            }

            .main-info-wrapper {
                position: relative;

                .main-table-wrapper {
                    padding: 25px 34px;
                }
            }

            .document-details-wrapp {

                .right-side-bar {

                    .right-side-bar-header {

                        .base-text-btn {

                            &.white {
                                @include close-modal-button;
                            }
                        }
                    }
                }
            }
        }
    }

    .document-rule-wrapper {

        .right-side-bar {
            color: #474849;

            .right-side-bar-header {

                .actions {

                    .base-text-btn {
                        position: absolute;
                        left: 23px;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                    }

                    .icon-close:before {
                        font-size: 24px;
                    }

                    .custom-small-dropdown-wrapper {

                        .active-btn {
                            margin-right: 0;
                        }
                    }
                }
                .base-text-btn {

                    &.white {
                       @include close-modal-button;
                    }
                }
            }

            .right-side-bar-body {
                padding: 0 0 81px 25px;
                overflow-y: auto;
                position: relative;
                overflow-y: hidden;

                .details {
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;

                    .field-group-wrapper {

                        .field-group {
                            width: fit-content;

                            .view-field {
                                width: 350px;
                            }
                        }
                    }
                    &::-webkit-scrollbar {
                        height: 28px;
                        width: 18px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0px 0px 10px 10px transparent;
                        border: solid 0px transparent;
                    }

                    &::-webkit-scrollbar-thumb:vertical {
                        box-shadow: inset 0 0 10px 10px #D8D8D8;
                        border-top: solid 10px transparent;
                        border-left: solid 7px transparent;
                        border-bottom: solid 10px transparent;
                        border-right: solid 7px transparent;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
                    }
                }

                .rule-details {
                    height: 100%;
                    overflow-y: auto;
                    padding: 23px;
                    overflow-x: hidden;

                    .field-group-wrapper {

                        .field-group {
                            padding: 0 35px;

                            .view-field {
                                color: #474849;
                                margin: 3px 0;
                                padding: 5px 0;

                                .base-input-label {
                                    font-size: 10px;
                                    font-weight: 600;
                                    line-height: 13px;
                                    text-transform: uppercase;
                                }

                                .view-value {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        height: 28px;
                        width: 18px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0px 0px 10px 10px transparent;
                        border: solid 0px transparent;
                    }

                    &::-webkit-scrollbar-thumb:vertical {
                        box-shadow: inset 0 0 10px 10px #D8D8D8;
                        border-top: solid 10px transparent;
                        border-left: solid 7px transparent;
                        border-bottom: solid 10px transparent;
                        border-right: solid 7px transparent;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
                    }
                }
            }
        }
    }
}
