.date-filter {
  display: flex;
  width: 290px;
  >div:first-child {
    margin-right: 16px;
  }
  >div {
    max-width: 138px;
    width: 50%;
    flex: 1;
  }
  .icon-calendar {
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.small-size {
  //Date picker
  .base-input-label {
    display: none;
  }
  .base-input-wrapper input {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 36px;
  }
  &.date-filter .base-input-wrapper input {
    padding-right: 30px;
  }
  .base-input-wrapper {
    font-weight:500;
    input {
      font-weight:500;
    }
    height: 34px;
  }
  .icon-calendar {
    right: 6px;
  }

  //selector
  .custom-select-wrapper {
    height: 34px;
    min-height: unset;
    max-height: unset;
  }
  .custom-select-wrapper:after {
    right: 6px;
    font-size: 23px;
  }

  .default-selected-label {
    position: relative!important;
    top: 0!important;
    left: 0!important;
    padding-left: 10.5px!important;
    display: flex;
    align-items: center;
  }
  .base-input-wrapper.multiple-autocomplete {
    min-height: unset!important;
    max-height: unset!important;
    overflow: hidden;
  }
  .multiple-autocomplete .selected,
  .custom-autocomplete .selected {
    padding: 0 15px;
    padding-right: 26px!important;
  }
  .multiple-autocomplete .item, .custom-autocomplete .item {
    margin-top: 7px;
  }

  .base-input-wrapper:before,
  .custom-select-wrapper:before,
  .custom-select-wrapper.focused:before,
  .custom-select-wrapper.open:before {
    height: 95%;
    display: none!important;
  }
}
